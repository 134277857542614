import {
    Box,
    Heading,
    ListItem,
    OrderedList,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    UnorderedList
} from "@chakra-ui/react";
import React from "react";

const hostname = window.location.hostname;

let SERVER_IP;
let SERVER_IP_MULTIDOC;
if (hostname === "dev.honeybear.ai" || hostname === "localhost") {
    SERVER_IP = 'https://100.24.205.128.nip.io';
    SERVER_IP_MULTIDOC = 'https://44.220.144.216.nip.io';
} else {
    SERVER_IP = 'https://3.89.226.93.nip.io';
    SERVER_IP_MULTIDOC = 'https://44.220.144.216.nip.io';
}
// let SERVER_IP = 'https://52.204.65.217.nip.io';

export { SERVER_IP };
export { SERVER_IP_MULTIDOC };

export const influencerMap = {
    "julia": "Hey fans of Julia Bedell! We think you’ll like us too!",
    "sree": "Hey fans of Sree! We think you’ll like us too!",
    "brandon": "Hey fans of Brandon! We think you’ll like us too!",
    "ellie": "Hey fans of Ellie! We think you’ll like us too!",
    "cecile": "Hey fans of Cecile! We think you’ll like us too!",
    "tingting": "Hey fans of TingTing! We think you’ll like us too!",
    "teo": "Welcome Fordham!!!",
    "alyssa-tabling" : "Welcome Cornell!!!"
}

// Heading sm is 16px
// Heading xs is 14px

// Text md is 16px
// Text sm is 14px
export const markdownComponents = {
    h1: ({ node, ...props }) => <Heading as="h1" fontSize={15.5} textDecoration="underline" my={'0.4rem'} {...props} />,
    h2: ({ node, ...props }) => <Heading as="h2" fontSize={15} my={'0.4rem'} {...props} />,
    h3: ({ node, ...props }) => <Heading as="h3" fontSize={15} my={'0.4rem'} {...props} />,
    h4: ({ node, ...props }) => <Heading as="h4" fontSize={15} my={'0.4rem'} {...props} />,
    h5: ({ node, ...props }) => <Heading as="h5" fontSize={15} my={'0.4rem'} {...props} />,
    h6: ({ node, ...props }) => <Heading as="h6" fontSize={15} my={'0.4rem'} {...props} />,
    em: ({ node, ...props }) => <Text as="em" fontStyle="italic" fontSize={15} {...props} />,
    strong: ({ node, ...props }) => <Text as="strong" fontWeight="bold" fontSize={15} {...props} />,
    ul: ({ node, ...props }) => <UnorderedList my={'0.4rem'} fontSize={15} {...props} />,
    ol: ({ node, ...props }) => <OrderedList my={'0.4rem'} fontSize={15} {...props} />,
    li: ({ node, ...props }) => <ListItem fontSize={15} {...props} />,
    table: ({ node, ...props }) => <Table variant="simple" size="sm" my={"0.75rem"} {...props} />,
    thead: Thead,
    tbody: Tbody,
    tr: Tr,
    th: ({ node, ...props }) => <Th px={"0.75rem"} py={"0.5rem"} fontSize={15} {...props} />,
    td: ({ node, ...props }) => <Td px={"0.75rem"} py={"0.5rem"} fontSize={15} {...props} />,
    p: ({ node, ...props }) => <Text fontSize={15} {...props} />,
    blockquote: ({ node, ...props }) => <Box as="blockquote" borderLeft="4px solid" fontSize={15}
                                             borderColor="gray.200" pl={"0.9rem"} my={"0.5rem"} fontStyle="italic" {...props} />
}

//<Heading as="h2" fontSize={["16px", "18px", "20px"]} my="0.4rem" {...props} />

// fontSize "xs" = 12px
// fontSize "sm" = 14px
// fontSize "md" = 16px
// fontSize "lg" = 18px


//borderRadius={'15px'} spacing={6} borderColor={'lightgray'} borderWidth={0.25}
//                                         shadow={`1.5px 1.8px 0px lightgray`}

