import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    VStack,
    HStack,
    Flex,
    Text,
    useColorModeValue,
    IconButton,
    Textarea,
    Button,
    Heading,
    UnorderedList,
    OrderedList,
    ListItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Tooltip,
    useDisclosure,
    useToast,
    ListIcon,
    List,
    Divider,
    Icon,
    Input,
    Radio,
    RadioGroup,
    CloseButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import {useDispatch, useSelector} from "react-redux";
import {MessageBubble} from "./MessageBubble";
import {useAuth} from "../../redux/AuthProvider";
import lightTheme from "../../utils/styles";
import {FaBars, FaCheckCircle} from "react-icons/fa";
import {AiOutlineArrowRight, AiOutlineSend} from "react-icons/ai";
import {RiCloseFill} from "react-icons/ri";
import {
    addDocument,
    removeDocument,
    setAskContent,
    setAskContentGeneration,
    setCurrentChatInput,
    setCurrentChatInputGeneration,
    setCurrentGenerationSessionToken, setCurrentGenerationWordCount,
    setFollowUpContent,
    setFollowUpContentGeneration, setIsPricingModalOpen,
    setIsScanActuallyInProgress,
    setIsUploadSourcesModalOpen,
    setScanInProgress,
    toggleIsAskVisibleFalse,
    toggleIsAskVisibleFalseGeneration,
    toggleIsFollowUpVisibleFalse,
    toggleIsFollowUpVisibleFalseGeneration,
    toggleIsFollowUpVisibleTrue
} from "../../redux/reducers";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import {BiStopCircle} from "react-icons/bi";
import {markdownComponents, SERVER_IP} from "../../utils/constants";
import SignUpScreen1 from "../Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../Modals/SignIn/SignInScreen1";
import {CheckCircleIcon, ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import { GrChatOption } from "react-icons/gr";
import {FcComments} from "react-icons/fc";
import {MessageBubbleGeneration} from "./MessageBubbleGeneration";
import {MdGraphicEq} from "react-icons/md";
import GeneratePromptScreen2 from "../Modals/Generation/GeneratePromptScreen2";
import {createGenerationSession, generateContent} from "../../utils/pdf-page-helpers";
import axios from "axios";
import {addDocumentInStorage, getDocumentsInStorage} from "../../utils/local-storage-helpers";
import {SlArrowUp} from "react-icons/sl";
import PricingScreen from "../Modals/Payments/PricingScreen";
import {useTranslation} from "react-i18next";

export const GenerationOld = ({ theDocumentGeneration, sessionTokenGeneration, socketIDGeneration,
                         stopClaudeResponseGeneration, startGeneration }) => {
    const auth = useAuth();
    const {t} = useTranslation();
    const lastMessageRef = useRef(null);
    const userBgColor = useColorModeValue(lightTheme.colors.lightGray, lightTheme.colors.lightGray);
    const claudeBgColor = useColorModeValue("white", "#2e93ea");
    const claudeColor = useColorModeValue("#000000", "#E2E8F0");
    const currentChatInputGeneration = useSelector(state => state.settings.currentChatInputGeneration);

    const { isTypingGeneration, setIsTypingGeneration } = useAuth();
    const {isThinkingGeneration, setIsThinkingGeneration} = useAuth();
    const generations = useSelector(state => state.documents.savedGenerations);
    const currentMessagesGeneration = generations.find(doc => doc.id === theDocumentGeneration.id).messages;
    // const currentMessagesGeneration = [];
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [wordCount, setWordCount] = useState("");

    const isAskVisibleGeneration = useSelector(state => state.settings.isAskVisibleGeneration);
    const isFollowUpVisibleGeneration = useSelector(state => state.settings.isFollowUpVisibleGeneration);
    const askContentGeneration = useSelector(state => state.settings.askContentGeneration);
    const followUpContentGeneration = useSelector(state => state.settings.followUpContentGeneration);
    const isUploadSourcesModalOpen = useSelector(state => state.settings.isUploadSourcesModalOpen);
    const currentGenerationWordCount = useSelector(state => state.settings.currentGenerationWordCount);

    const dispatch = useDispatch();
    const textareaRef = useRef(null);
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const toast = useToast();
    const [shouldReferenceSources, setShouldReferenceSources] = React.useState('true');
    const [sliderValue, setSliderValue] = React.useState(1000);
    const [showTooltip, setShowTooltip] = React.useState(true);
    const [isWindowExpanded, setIsWindowExpanded] = useState(true);
    const toggleWindow = () => setIsWindowExpanded(!isWindowExpanded);
    const isProPlan = useSelector((state) => state.settings.isProPlan);
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();

    // startGeneration(prompt, sliderValue, shouldReferenceSources);
    const modifiedHandleNewMessage = () => {
        if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isChatExceeded") === "true") {
            toast({
                title: t("(Free Plan) Chat Limit Exceeded!"),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            dispatch(setIsPricingModalOpen(true));
            dispatch(setCurrentChatInput(''));
            if (textareaRef && textareaRef.current) {
                textareaRef.current.style.height = '3.5rem';
            }
            return;
        }

        if (currentMessagesGeneration && currentMessagesGeneration.length >= 3 && localStorage.getItem("userID") === null) {
            toast({
                title: t("You've reached your generation limit in guest mode!"),
                description: t("Please sign up to continue creating writing."),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            onOpenSignUpScreen1();
            const chatInput = currentChatInputGeneration;
            dispatch(setCurrentChatInputGeneration(''));
            if (textareaRef && textareaRef.current) {
                textareaRef.current.style.height = '3.5rem';
            }
            return;
        }
        const chatInput = currentChatInputGeneration;
        dispatch(setCurrentChatInputGeneration(''));

        const shouldReferenceSourcesBoolean = shouldReferenceSources === 'true';

        if (currentGenerationWordCount !== -1) {
            startGeneration(chatInput, currentGenerationWordCount, shouldReferenceSourcesBoolean);
        } else {
            startGeneration(chatInput, sliderValue, shouldReferenceSourcesBoolean);
            dispatch(setCurrentGenerationWordCount(sliderValue));
        }

        dispatch(setCurrentChatInputGeneration(''));
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = '3.5rem';
        }
    };

    const modifiedHandleNewMessageRetry = () => {
        // console.log(currentMessagesGeneration[0]);

        // get the question from the most recent message instead of the very first message
        const chatInput = currentMessagesGeneration[currentMessagesGeneration.length - 2].question;

        dispatch(setCurrentChatInputGeneration(''));

        if (currentGenerationWordCount !== -1) {
            // console.log("currentGenerationWordCount", currentGenerationWordCount);
            startGeneration(chatInput, currentGenerationWordCount, true);
        } else {
            // console.log("sliderValue", sliderValue);
            startGeneration(chatInput, sliderValue, true);
            dispatch(setCurrentGenerationWordCount(sliderValue));
        }
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = '3.5rem';
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (currentChatInputGeneration !== "" && !isTypingGeneration && !isThinkingGeneration) {
                modifiedHandleNewMessage();
            }
        }
    };

    useEffect(() => {
        const scrollMessageIntoView = () => {
            if (lastMessageRef.current) {
                // console.log('Height of last message:', lastMessageRef.current.offsetHeight);
                if (lastMessageRef.current.offsetHeight < 500) {
                    lastMessageRef.current.scrollIntoView({ behavior: 'instant', block: 'end' });
                } else {
                    lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            }
        };
        scrollMessageIntoView();

    }, [currentMessagesGeneration]);

    useEffect(() => {
        const scrollMessageIntoView = () => {
            if (lastMessageRef.current) {
                lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        };
        scrollMessageIntoView();

    }, []);

    useEffect(() => {
        if (textareaRef.current && isAskVisibleGeneration) {
            textareaRef.current.focus();
        }
    }, [isAskVisibleGeneration]);

    useEffect(() => {
        if (textareaRef.current && isFollowUpVisibleGeneration) {
            textareaRef.current.focus();
        }
    }, [isFollowUpVisibleGeneration]);

    const chatContainerRef = useRef(null);
    const secondToLastMessageRef = useRef(null);

    const [promptStrength, setPromptStrength] = useState(t("Weak prompt: add more context for higher quality generations"));

    const determinePromptStrength = (prompt) => {
        // Implement your logic here. This is a simple placeholder.
        const length = prompt.length;
        if (length < 50) return t("Weak prompt: add more context for higher quality generations");
        if (length >= 50 && length < 100) return t("Average prompt: consider including important keywords");
        return t("Great prompt: honeybear.ai will use this to generate text");
    };

    const handleInputChange = (e) => {
        const newPrompt = e.target.value;
        dispatch(setCurrentChatInputGeneration(newPrompt));
        setPromptStrength(determinePromptStrength(newPrompt));
    };

    const PromptStrengthBar = () => {
        // Define the styles for different strengths
        const styles = {
            W: { width: '33%', backgroundColor: 'red' },
            A: { width: '66%', backgroundColor: 'red' },
            G: { width: '100%', backgroundColor: 'red' },
        };

        return (
            <div style={{
                height: '3px',
                backgroundColor: 'lightgray',
                borderRadius: '2px',
                marginTop: '10px',
                width: '100%',
            }}>
                <div style={{
                    ...styles[promptStrength.charAt(0)],
                    transition: 'width 0.5s ease-in-out', // Smooth transition for width change
                    height: '100%',
                    borderRadius: '2px',
                }}></div>
            </div>
        );
    };

    const placeholders = [t("Revise the response to be more concise and to the point..."),
        t("Improve the thesis statement and support it with better details..."), t("Rewrite this with a more collegiate tone...")];
    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true); // State to manage fade in and out

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
                setCurrentPlaceholder(placeholders[index]);
                setFade(true);
            }, 750); // Half the interval time to allow for fade in and fade out
        }, 1500);

        return () => clearInterval(intervalId);
    }, [index]);

    const placeholdersInitial = [t("Write a cover letter based on my uploaded resume."),
        t("Write a discussion post that addresses particularly interesting points in the uploaded sources."),
        t("Both the methods in these two papers relate to Q Learning in continuous action spaces, but how do they differ? One is sampling based and one is using a Q function with a known closed form, but can you be more specific?"),
        t("I am interesting in working with the authors of these papers as a potential phd student. Please summarize the main work, and give me some intelligent things I can talk about with them. Make sure to compare and contrast all of the works."),
        t("Write a paragraph about how this text relates to the rising wealth gap in the United States."),
        t("Compare and contrast each uploaded source."),
        t("Create an outline for a research paper based on the uploaded sources"),
        t("Imagine that you have recently been appointed as the new National Security Advisor to Prime Minister Trudeau. He asks you to prepare a memo about what Canada should do regarding foreign aid. Should we give more, less, the same, or none? And if you do advocate for aid, how do you think it should be allocated? You must explain the reasoning (including the theoretical paradigm that animates it) behind your decision in a serious policy response."),
        t("Liberalism and realism are two theoretical paradigms of international relations. Apply both of them to the Iran Hostage Crisis of 1979-1981. Be sure to say which paradigm you think explains the event better and why."),
        // "I'm writing a research paper on the use of implicatures in comedy. can you write me a few pages for that topic based on this paper in essay format",
        t("'Just because a significant part of our interactions in the public sphere are happening in the digital realm does not mean that we have to adapt the norms (laws and/or conventions) which govern and shape the public sphere.' To which extent do you agree or disagree with this statement and why? Use concrete examples from the uploaded readings to support your arguments."),
        t("I’m writing a paper on the topic of the uploaded case study. I also attached the guidelines for writing this paper. Please write in prose. Follow the breakdowns given in the guide document.")];
    const [currentPlaceholderInitial, setCurrentPlaceholderInitial] = useState(placeholdersInitial[0]);
    const [indexInitial, setIndexInitial] = useState(0);
    const [fadeInitial, setFadeInitial] = useState(true); // State to manage fade in and out

    useEffect(() => {
        const intervalIdInitial = setInterval(() => {
            setFadeInitial(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndexInitial((prevIndex) => (prevIndex + 1) % placeholdersInitial.length);
                setCurrentPlaceholderInitial(placeholdersInitial[indexInitial]);
                setFadeInitial(true);
            }, 1100); // Half the interval time to allow for fade in and fade out
        }, 2200);

        return () => clearInterval(intervalIdInitial);
    }, [indexInitial]);

    const buttons = [
        { text: t("Shorten"), colorScheme: 'orange', bg: 'orange.400', _hover: { bg: 'orange.500' } },
        { text: t("Make Longer"), colorScheme: 'orange', bg: 'orange.400', _hover: { bg: 'orange.500' } },
        { text: t("Elaborate"), colorScheme: 'orange', bg: 'orange.400', _hover: { bg: 'orange.500' } },
        // { text: "More Casual", colorScheme: 'orange', bg: 'orange.400', _hover: { bg: 'orange.500' } },
        // { text: "More Formal", colorScheme: 'orange', bg: 'orange.400', _hover: { bg: 'orange.500' } },
        { text: t("Reformat"), colorScheme: 'orange', bg: 'orange.400', _hover: { bg: 'orange.500' } },
        { text: t("Retry"), color: 'white', bgGradient: 'linear(to-r, red.400,pink.400)', _hover: { bgGradient: 'linear(to-r, red.400,pink.400)', boxShadow: 'xl' } },
    ];

    const handleButtonClick = (buttonName) => {
        switch (buttonName) {
            case t("Shorten"):
                dispatch(setCurrentChatInputGeneration(t("Please shorten the response")));
                textareaRef.current.focus();
                break;
            case t("Make Longer"):
                dispatch(setCurrentChatInputGeneration(t("Please make the response longer")));
                textareaRef.current.focus();
                break;
            case t("Elaborate"):
                dispatch(setCurrentChatInputGeneration(t("Please elaborate using more details from the sources")));
                textareaRef.current.focus();
                break;
            case t("Reformat"):
                dispatch(setCurrentChatInputGeneration(t("Please reformat the response into paragraphs")));
                textareaRef.current.focus();
                break;
            case t("Retry"):
                modifiedHandleNewMessageRetry();
                break;
            default:
                console.log(`Unhandled button: ${buttonName}`);
        }
    };


    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" p={0} boxShadow="sm" borderRadius="md"
             border="0px" borderColor="gray.300" h="92.5vh" maxW="100%">
            <Box h={currentMessagesGeneration.length > 0 ? '90.5%': '100%'} overflowY="auto" className={currentMessagesGeneration.length > 7 ? "delayed-survey" : "normal"}
                 ref={chatContainerRef}>
                <Flex justifyContent={'flex-start'} flexDirection={'column'} height={'100%'}>
                    {currentMessagesGeneration.length > 0 ?
                        currentMessagesGeneration.slice(-40).map((message, index, slicedArray) => {
                            const isLastMessage = index === slicedArray.length - 1;
                            const isSecondToLastMessage = index === slicedArray.length - 2;
                            return (
                                <MessageBubbleGeneration
                                    sessionToken={sessionTokenGeneration}
                                    socketID={socketIDGeneration}
                                    message={message}
                                    isLastMessage={isLastMessage}
                                    userBgColor={userBgColor}
                                    claudeBgColor={claudeBgColor}
                                    claudeColor={claudeColor}
                                    lastMessageRef={lastMessageRef}
                                    isSecondToLastMessage={isSecondToLastMessage}
                                    secondToLastMessageRef={secondToLastMessageRef}
                                    chatContainerRef={chatContainerRef}
                                />
                            );
                        }) :

                        <Flex justifyContent={'flex-start'} flexDirection={'column'} height={"100vh"} alignItems={"center"}>
                            <VStack spacing={12} mt={7} borderWidth={0} width={'70%'} height={'100%'} align={'flex-start'}>
                                <VStack align={'flex-start'} width={'100%'} spacing={3}>
                                    <Text fontSize={'4xl'} fontWeight={'800'} >{t("Let's Generate Writing")}</Text>
                                    <Text fontSize={'lg'} fontWeight={'600'} color={'gray'}>{t("Upload sources in the left panel.")}</Text>
                                    <Text fontSize={'lg'} fontWeight={'600'} color={'gray'}>{t("Then enter a prompt to generate long-form text based on the sources.")}</Text>
                                </VStack>

                                <VStack align={'flex-start'} width={'100%'} spacing={1} mt={-1}>
                                    <Textarea
                                        name="message-input"
                                        ref={textareaRef}
                                        placeholder={currentPlaceholderInitial}
                                        value={currentChatInputGeneration}
                                        onChange={handleInputChange}
                                        minH={'12rem'}
                                        overflowY="auto"  // Allow vertical scrollbar
                                        maxH="18rem"
                                        resize="none"  // Disable manual resizing
                                        onInput={(e) => {  // Auto-expand as text overflows
                                            e.currentTarget.style.height = 'auto';
                                            e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
                                        }}
                                        required
                                        width={'100%'}
                                    />
                                    <PromptStrengthBar />
                                    <Text fontSize={'sm'} mt={2}>{promptStrength}</Text>
                                </VStack>


                                <Slider
                                    id='slider'
                                    defaultValue={1000}
                                    min={200}
                                    max={2000}
                                    mt={10}
                                    colorScheme='teal'
                                    onChange={(v) => setSliderValue(v)}
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                    <SliderTrack bg='red.100' h={2} borderColor={'lightgray'} borderWidth={0.25}
                                                 shadow={`1.5px 1.8px 0px lightgray`}>
                                        <SliderFilledTrack bg='tomato' />
                                    </SliderTrack>
                                    <Tooltip
                                        hasArrow
                                        bg="black"
                                        py={1}
                                        px={2}
                                        color="white"
                                        fontWeight={'bold'}
                                        placement="bottom"
                                        isOpen={!isUploadSourcesModalOpen}
                                        label={`${sliderValue} words`}
                                        fontSize={'sm'}
                                    >
                                        <SliderThumb boxSize={6} color={'black'} borderColor={'lightgray'} borderWidth={0.25}
                                                     shadow={`1.5px 1.8px 0px lightgray`}/>
                                    </Tooltip>
                                    <Tooltip
                                        hasArrow
                                        bg="black"
                                        py={1}
                                        px={2}
                                        color="white"
                                        placement="top"
                                        isOpen={!isUploadSourcesModalOpen}
                                        label={t("Desired Word Count")}
                                        fontSize={'xs'}
                                    >
                                        <SliderThumb boxSize={6} color={'black'} borderColor={'lightgray'} borderWidth={0.25}
                                                     shadow={`1.5px 1.8px 0px lightgray`}/>
                                    </Tooltip>
                                </Slider>
                                {/*<RadioGroup value={shouldReferenceSources} width={'100%'}>*/}
                                {/*    <HStack spacing={4} mt={10} width={'100%'} align={'space-between'}>*/}
                                {/*        <Box width="100%" padding={5}*/}
                                {/*             bg={shouldReferenceSources === 'true' ? 'blue.100' : 'white'}*/}
                                {/*             _hover={shouldReferenceSources !== 'true' ? { bg: 'blue.50' } : {}}*/}
                                {/*             borderColor={'lightgray'} borderWidth={0.25}*/}
                                {/*             shadow={`1.5px 1.8px 0px lightgray`}*/}
                                {/*             borderRadius="md" cursor="pointer"*/}
                                {/*             onClick={() => setShouldReferenceSources('true')}*/}
                                {/*             display="flex" flex="1">*/}
                                {/*            <VStack align="start">*/}
                                {/*                <Radio size="lg" value="true">*/}
                                {/*                    <Text fontWeight="bold" fontSize={15}>Reference uploaded sources (Recommended)</Text>*/}
                                {/*                </Radio>*/}
                                {/*                <Text paddingLeft={'1.75rem'} fontSize={14} noOfLines={5}>*/}
                                {/*                    Will reference the sources uploaded in the left panel to help generate the text</Text>*/}
                                {/*            </VStack>*/}
                                {/*        </Box>*/}
                                {/*        <Box width="100%" padding={5}*/}
                                {/*             bg={shouldReferenceSources === 'false' ? 'blue.100' : 'white'}*/}
                                {/*             _hover={shouldReferenceSources !== 'false' ? { bg: 'blue.50' } : {}}*/}
                                {/*             borderColor={'lightgray'} borderWidth={0.25}*/}
                                {/*             shadow={`1.5px 1.8px 0px lightgray`}*/}
                                {/*             borderRadius="md" cursor="pointer"*/}
                                {/*             onClick={() => setShouldReferenceSources('false')}*/}
                                {/*             display="flex" flex="1">*/}
                                {/*            <VStack align="start">*/}
                                {/*                <Radio size="lg" value="false">*/}
                                {/*                    <Text fontWeight="bold" fontSize={15}>Don't reference any sources</Text>*/}
                                {/*                </Radio>*/}
                                {/*                <Text paddingLeft={'1.75rem'} fontSize={14} noOfLines={5}>*/}
                                {/*                    Will just generate text without citing sources</Text>*/}
                                {/*            </VStack>*/}
                                {/*        </Box>*/}
                                {/*    </HStack>*/}
                                {/*</RadioGroup>*/}
                                <Button
                                    loadingText="Starting up..."
                                    size="md"
                                    mt={6}
                                    onClick={() => {
                                        if (generations.find(doc => doc.id === theDocumentGeneration.id).files.length === 0) {
                                            toast({
                                                title: t("No sources uploaded!"),
                                                description: t("Please upload sources in the left panel."),
                                                status: "error",
                                                duration: 9000,
                                                isClosable: true,
                                                position: "top",
                                            })
                                        } else if (currentChatInputGeneration === "") {
                                            toast({
                                                title: t("No prompt entered!"),
                                                description: t("Please enter a prompt to generate text."),
                                                status: "error",
                                                duration: 9000,
                                                isClosable: true,
                                                position: "top",
                                            })
                                        }
                                        else {
                                            modifiedHandleNewMessage();
                                        }
                                    }}
                                    bgGradient="linear(to-r, red.400,pink.400)"
                                    color={'white'}
                                    _hover={{
                                        bgGradient: 'linear(to-r, red.400,pink.400)',
                                        boxShadow: 'xl',
                                    }}>
                                    {t("GENERATE")}
                                </Button>
                            </VStack>
                        </Flex>
                    }
                </Flex>
            </Box>
            {currentMessagesGeneration.length !== 0 ?
                <Box position="relative" mt="auto">
                    <HStack alignItems="center" bg={"white"} p={2}>
                        <Menu placement={'top-start'}>
                            <MenuButton
                                as={Button}
                                alignSelf={"center"}
                                rounded={'full'}
                                size={'sm'}
                                px={5}
                                ml={2}
                                colorScheme={'orange'}
                                bg={'orange.400'}
                                _hover={{ bg: 'orange.500' }}>
                                {t("Tone")}
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => {
                                    dispatch(setCurrentChatInputGeneration(t("Please write the response in more formal language")));
                                    textareaRef.current.focus();
                                }}>{t("More Formal")}</MenuItem>
                                <MenuItem onClick={() => {
                                    dispatch(setCurrentChatInputGeneration(t("Please write the response in more casual language")));
                                    textareaRef.current.focus();
                                }}>{t("More Casual")}</MenuItem>
                            </MenuList>
                        </Menu>
                        {buttons.map((button, index) => (
                            <Button
                                key={index}
                                alignSelf={"center"}
                                rounded={'full'}
                                size={'sm'}
                                px={5}
                                ml={2}
                                colorScheme={button.colorScheme}
                                bg={button.bg}
                                color={button.color}
                                bgGradient={button.bgGradient}
                                _hover={button._hover}
                                onClick={() => {
                                    handleButtonClick(button.text);
                                }}>
                                {button.text}
                            </Button>
                        ))}
                    </HStack>

                    <form onSubmit={modifiedHandleNewMessage} style={{backgroundColor: 'white'}}>
                        <HStack spacing={0} width={'100%'}>
                            <VStack align="center" h="auto" marginX={'0.5rem'} marginBottom={'0.4rem'}
                                    borderWidth={'1px'}
                                    borderColor={isInputFocused ? 'blue' : lightTheme.colors.darkGray}
                                    borderRadius={'md'} width={'96%'}>
                                <HStack width={'100%'} position={'relative'} spacing={0} align={"flex-end"}
                                        justifyContent={'space-between'}>
                                    <Textarea
                                        name="message-input"
                                        ref={textareaRef}
                                        placeholder={currentPlaceholder}
                                        onKeyDown={handleKeyDown}
                                        value={currentChatInputGeneration}
                                        style={{
                                            border: 'none',
                                            borderRight: 'none',
                                            borderRightColor: "white",
                                            marginRight: 0,
                                            borderTopWidth: isAskVisibleGeneration || isFollowUpVisibleGeneration ? '0px' : '1px',
                                            borderTopLeftRadius: isAskVisibleGeneration || isFollowUpVisibleGeneration ? '0px' : '10px',
                                            borderTopRightRadius: isAskVisibleGeneration || isFollowUpVisibleGeneration ? '0px' : '10px'
                                        }}
                                        _focus={{boxShadow: 'none', outline: 'none'}}
                                        onChange={(e) => dispatch(setCurrentChatInputGeneration(e.target.value))}
                                        minH={'3.5rem'}
                                        onFocus={() => setIsInputFocused(true)}
                                        onBlur={() => setIsInputFocused(false)}
                                        overflowY="auto"  // Allow vertical scrollbar
                                        maxH="29rem"
                                        resize="none"  // Disable manual resizing
                                        onInput={(e) => {  // Auto-expand as text overflows
                                            e.currentTarget.style.height = 'auto';
                                            e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
                                        }}
                                        required
                                        width={'92%'}
                                    />
                                    <Box width={'2.85rem'}>
                                        <Button
                                            aria-label="Send message"
                                            type="submit"
                                            isDisabled={!currentChatInputGeneration || (isTypingGeneration || isThinkingGeneration)}
                                            position="relative"
                                            cursor="pointer"
                                            _hover={{backgroundColor: 'white'}}
                                            borderRadius="md"
                                            backgroundColor={"white"}
                                            transition="background 0.2s"
                                            height={'2.5rem'}
                                            width={'100%'}
                                            size={'32'}
                                            onClick={() => {
                                                modifiedHandleNewMessage();
                                            }}
                                        >
                                            <AiOutlineSend style={{fontSize: '22px'}}/>
                                        </Button>
                                    </Box>
                                </HStack>
                            </VStack>
                        </HStack>
                    </form>
                </Box>
                : null}
            {currentMessagesGeneration.length !== 0 ?
                <Box justifyContent={"flex-start"} display={"flex"} ml={'0.61rem'}>
                    <Text fontSize={"xs"} color={"gray"}>{t("Shift + Enter to add a new line")}</Text>
                </Box>
                : null}
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1}
                           onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1}
                           onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
            {/*<GeneratePromptScreen2 isOpenGeneratePromptScreen2={isOpenGeneratePromptScreen2}*/}
            {/*                       onCloseGeneratePromptScreen2={onCloseGeneratePromptScreen2}/>*/}
        </Box>
    );
};