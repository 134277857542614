import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Grid,
    GridItem,
    VStack,
    Text,
    HStack,
    useDisclosure, useToast, Button, useBreakpointValue, Image, Icon,
} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";

const DocumentGridVideosEmpty = () => {
    const {t} = useTranslation();

    return (
        <VStack width={['95%','88%', '78%', '68%', '58%']}>
            <VStack spacing={4} width={'100%'} bg={'white'} mb={'10rem'}>
                <Text fontSize={18} fontWeight={'bold'}>{t("No videos yet")}</Text>
                <Text color={"gray.700"} textAlign={'center'}>{t("Enter link to a public YouTube video above")}</Text>
                <Text color={"gray.700"} textAlign={'center'}>{t("Then you can chat just like with a normal PDF!")}</Text>
            </VStack>
        </VStack>
    );
};

export default DocumentGridVideosEmpty;
