import axios from "axios";
import {SERVER_IP} from "./constants";
import {SERVER_IP_MULTIDOC} from "./constants";

export const initializeMultidoc = async (multidoc_title, user_id) => {
    console.log("body for initialize_multidoc", {
        "multidoc_title": multidoc_title.toString(),
        "user_id": user_id.toString(),
    });

    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/initialize_multidoc', {
            "multidoc_title": multidoc_title.toString(),
            "user_id": user_id.toString(),
        });
        console.log("initialize_multidoc_clicked", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "id": response.data.message.multidocID,
                // "id": response.data.message.generationID,
                // "name": response.data.message.generationTitle,
                // "messages": response.data.message.generations,
                // "files": response.data.message.sources,
            }
        }
    } catch (error) {
        console.log("/initialize_multidoc error", error);
        return null;
    }
}

export const createMultidocSession = async (multidoc_id, user_id) => {
    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/create_multidoc_session', {
            "multidoc_id": multidoc_id.toString(),
            "user_id": user_id.toString(),
        });
        console.log("create_multidoc_session_clicked", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "multidocSessionToken": response.data.message.multidoc_session_token,
                // "id": response.data.message.mulID,
                // "name": response.data.message.multidocTitle,
                // "messages": response.data.message.chats,
                // "files": response.data.message.sources,
            }
        }
    } catch (error) {
        console.log("/create_multidoc_session error", error);
        return null;
    }
}

export const getMultidoc = async (multidoc_id, user_id) => {
    console.log("/get_multidoc REQUEST BODY", {
        "multidoc_id": multidoc_id.toString(),
        "user_id": user_id.toString(),
    });
    // response.data.session_token or response.data.url
    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/get_multidoc', {
            "multidoc_id": multidoc_id.toString(),
            "user_id": user_id.toString(),
        });
        console.log("response for get_multidoc", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "id": response.data.message.multidocID,
                "name": response.data.message.multidocTitle,
                "messages": response.data.message.chats,
                "files": response.data.message.sources,
            }
        }
    } catch (error) {
        console.log("/get_multidoc error", error);
        return null;
    }
}

export const fetchMultidocs = async (userID) => {
    console.log("fetch multidocs userID", userID)
    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/list_multidocs', {
            "user_id" : userID,
        });
        console.log("response for fetch multidocs", response)
        if (response.data.status === 200) {
            const documentListGeneration = [];
            for (let i = 0; i < response.data.message.length; i++) {
                documentListGeneration.push({
                    "id" : response.data.message[i].multidoc_id,
                    "name" : response.data.message[i].multidoc_title,
                    "lastOpenTime" : response.data.message[i].last_open_time,
                    "messages" : [],
                    "files" : [],
                });
            }
            return {
                "status" : 200,
                "multidocs" : documentListGeneration,
            }
        }
    } catch (error) {
        console.log("/list_multidocs error", error);
        return null;
    }
}

export const multidocChat = async (user_id, generation_session_token, prompt,
                                      socketID, toast, messageHistory) => {
    console.log("multidoc chat request body", {
        "user_id": user_id.toString(),
        "multidoc_session_token": generation_session_token.toString(),
        "prompt": prompt.toString(),
        // "word_count": word_count,
        // "should_reference_sources": should_reference_sources,
        "socketio_sid": socketID,
        "message_history": messageHistory,
    })

    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/chat_with_multidoc', {
            "user_id": user_id.toString(),
            "multidoc_session_token": generation_session_token.toString(),
            "prompt": prompt.toString(),
            // "word_count": word_count,
            // "should_reference_sources": should_reference_sources,
            "socketio_sid": socketID,
            "message_history": messageHistory,
        });
        console.log("response for /chat_with_multidoc", response)

        if (response.data.status === 429) {
            // this is actually rate limit exceeded
            toast({
                title: "We’re experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
            return null;
        }
        else if (response.data.message === "multidoc session token invalid or expired") {
            toast({
                title: "Session expired. Please RELOAD the page to continue chatting!",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
            return null;
        }
        else if (response.data.status === 402) {
            toast({
                title: "(Free Plan) Chat Limit Exceeded!",
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            return {
                "status" : 402,
                "multidocSessionToken": "",
                "celeryTaskID": "",
            }
        }
        else {
            return {
                "status" : 200,
                "multidocSessionToken": response.data.message.multidoc_session_token,
                "response": response.data.message.response,
            }
        }
    } catch (error) {
        console.log("/chat_with_multidoc error", error);
        toast({
            title: "We’re experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.",
            status: "error",
            duration: 5500,
            isClosable: true,
            position: "top"
        })
        return null;
    }
}

export const addActiveSourcesMultidoc = async (multidoc_id, user_id, source_ids) => {
    console.log("/add_source_to_active_session REQUEST BODY", {
        "multidoc_session_token": multidoc_id.toString(),
        "user_id": user_id.toString(),
        "source_ids": source_ids,
    });
    // response.data.session_token or response.data.url
    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/add_source_to_active_session', {
            "multidoc_session_token": multidoc_id.toString(),
            "user_id": user_id.toString(),
            "source_ids": source_ids,
        });
        console.log("response for add_source_to_active_session", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "sources_added": response.data.message.sources_added,
                "sources_unable_to_add": response.data.message.sources_unable_to_add,
                "num_tokens_used": response.data.message.num_tokens_used,
                "num_tokens_left": response.data.message.num_tokens_left,
            }
        }
    } catch (error) {
        console.log("/add_source_to_active_session error", error);
        return null;
    }
}

export const removeActiveSourcesMultidoc = async (multidoc_id, user_id, source_id) => {
    console.log("/remove_source_from_active_session REQUEST BODY", {
        "multidoc_session_token": multidoc_id.toString(),
        "user_id": user_id.toString(),
        "source_id": source_id,
    });
    // response.data.session_token or response.data.url
    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/remove_source_from_active_session', {
            "multidoc_session_token": multidoc_id.toString(),
            "user_id": user_id.toString(),
            "source_id": source_id,
        });
        console.log("response for remove_source_from_active_session", response)

        if (response.data.status === 429) {
            alert("We're experiencing unusually high traffic right now. Please try again in a few minutes!")
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "active_sources": response.data.message.active_sources,
                "num_tokens_used": response.data.message.num_tokens_used,
                "num_tokens_left": response.data.message.num_tokens_left,
            }
        }
    } catch (error) {
        console.log("/remove_source_from_active_session error", error);
        return null;
    }
}

export const renameMultidoc = async (newName, userID, generationID) => {
    console.log("RENAME MULTIDOC payload", {
        "new_multidoc_title": newName.toString(),
        "user_id": userID.toString(),
        "multidoc_id": generationID.toString
    });

    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/rename_multidoc', {
            "new_multidoc_title" : newName.toString(),
            "user_id" : userID.toString(),
            "multidoc_id" : generationID.toString(),
        });
        console.log("response from /multidoc/rename_multidoc", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 401) {
            return {
                "status" : 401,
            }
        }
    } catch (error) {
        console.log("/multidoc/rename_multidoc", error);
        return null;
    }
}

export const deleteMultidoc = async (userID, generationID) => {
    console.log("DELETE MULTIDOC payload", {
        "user_id": userID,
        "multidoc_id": generationID,
    });

    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/delete_multidoc', {
            "user_id" : userID,
            "multidoc_id" : generationID,
        });
        console.log("response from /multidoc/delete_multidoc", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 401) {
            return {
                "status" : 401,
            }
        }
    } catch (error) {
        console.log("/multidoc/delete_multidoc error", error);
        return null;
    }
}

export const removeSourceMultidoc = async (userID, fileID, multidocID, multidocSessionID) => {
    console.log("REMOVE SOURCE MULTIDOC payload", {
        "user_id": userID,
        "file_id": fileID,
        "multidoc_id": multidocID,
        "multidoc_session_id": multidocSessionID,
    });

    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/remove_source_multidoc', {
            "user_id" : userID,
            "file_id" : fileID,
            "multidoc_id" : multidocID,
            "multidoc_session_id" : multidocSessionID,
        });
        console.log("response from /multidoc/remove_source_multidoc", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 401) {
            return {
                "status" : 401,
            }
        }
    } catch (error) {
        console.log("/multidoc/remove_source_multidoc error", error);
        return null;
    }
}

export const postChatPairMultidoc = async (listOfMessages, userID, multidocID, isLoggedIn) => {
    const requestBody = {
        "chat": listOfMessages,
        "user_id": isLoggedIn ? userID : "11111111-1111-1111-1111-111111111111",
        "multidoc_id": multidocID
    }
    console.log("postChatPairMultidoc request body", requestBody)
    try {
        const response = await axios.post(SERVER_IP_MULTIDOC + '/multidoc/save_chat', requestBody);
        console.log("response from multidoc post chat", response)

        // if (response.status === 200) {
        //     return response.data.text;
        // } else {
        //     throw new Error('getClaudeResponse error');
        // }
    } catch (error) {
        console.log("post chat multidoc error", error);
    }
}


