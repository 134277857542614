import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast
} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {changeNameOfDocumentGeneration} from "../../redux/reducers";
import {renameGeneration} from "../../utils/home-page-helpers";
import {useTranslation} from "react-i18next";
import {renameMultidoc} from "../../utils/multi-doc-helpers";

const EditGenerationTitle = ({ isOpenEditGenerationTitle, onCloseEditGenerationTitle, currentTitle, userID, generationID }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(currentTitle);
    const toast = useToast();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const renameGenerationHere = async () => {
        if (title === "") {
            toast({
                title: t("Please enter a title."),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        setIsLoading(true)
        await renameMultidoc(title, userID, generationID).then((response) => {
            setIsLoading(false);
            onCloseEditGenerationTitle();
            if (response === null) {
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 401) {
                toast({
                    title: t("401 error. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                dispatch(changeNameOfDocumentGeneration({
                    newName: title,
                    documentID: generationID,
                }));
                toast({
                    title: t("Successfully renamed."),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            }
        });
    };

    return (
        <>
            <Modal isOpen={isOpenEditGenerationTitle} onClose={onCloseEditGenerationTitle} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="18rem" w="30rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={onCloseEditGenerationTitle}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="xl" fontWeight="bold">{t("Rename")}</Text>
                            {/*<Text fontSize="sm" fontWeight="light" textAlign={"center"}>*/}
                            {/*    This document and its chat/notes history will be deleted.*/}
                            {/*    You can always reupload the same document later and start from scratch.</Text>*/}
                            <Input id="title" defaultValue={currentTitle} placeHolder={t("Rename this generation")}
                                   value={title} autoFocus={true}
                                   onChange={(e) => {
                                setTitle(e.target.value);
                            }}/>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4}>
                            <Stack spacing={10} pt={4} >
                                <Button
                                    loadingText="Submitting"
                                    size="lg"
                                    isLoading={isLoading}
                                    bgGradient="linear(to-r, red.400,pink.400)"
                                    color={'white'}
                                    _hover={{
                                        bgGradient: 'linear(to-r, red.400,pink.400)',
                                        boxShadow: 'xl',
                                    }}
                                    onClick={renameGenerationHere}>
                                    {t("Save")}
                                </Button>
                            </Stack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditGenerationTitle;



















//<Modal isOpen={isOpen} onClose={onClose}>
//             <ModalOverlay/>
//             <ModalContent maxW={["sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl"]}>
//                 <ModalHeader>{'Rename Generation'}</ModalHeader>
//                 <ModalCloseButton/>
//                 <form onSubmit={editTitle}>
//                     <ModalBody>
//                         <VStack w="100%" textAlign="left">
//                             <Input id="title" defaultValue={currentTitle} placeHolder="Untitled Generation"/>
//                         </VStack>
//
//                     </ModalBody>
//
//                     <ModalFooter>
//                         <Button variant="ghost" onClick={onClose}>Cancel</Button>
//                         <Button colorScheme="blue" mr={3} type="submit">
//                             Save
//                         </Button>
//                     </ModalFooter>
//                 </form>
//             </ModalContent>
//         </Modal>
//     </>
