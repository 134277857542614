import {useNavigate} from "react-router-dom";
import {Box, Button, HStack, Icon, useDisclosure, VStack, Text} from "@chakra-ui/react";
import lightTheme from "../../utils/styles";
import React from "react";
import {SideBarFileUploadButton} from "./SideBarFileUploadButton";
import {ImFilePdf} from "react-icons/im";
import {useDispatch, useSelector} from "react-redux";
import GeneratePromptScreen2 from "../../components/Modals/Generation/GeneratePromptScreen2";
import {setIsUploadSourcesModalOpen} from "../../redux/reducers";
import { IoDocumentsOutline } from "react-icons/io5";
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import {useTranslation} from "react-i18next";
import {SideBarFileUploadButtonOld} from "./SideBarFileUploadButtonOld";
import GeneratePromptScreen2Old from "../../components/Modals/Generation/GeneratePromptScreen2Old";

export const SideBarFileUploadOld = ({generation}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const documents = useSelector(state => state.documents.savedDocuments)
    const { isOpen: isOpenGeneratePromptScreen2, onOpen: onOpenGeneratePromptScreen2, onClose: onCloseGeneratePromptScreen2 } = useDisclosure();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const {t} = useTranslation();

    return (
        <VStack width={'100%'} height={'100%'} >
            {generation.files.length === 0 ?
                <VStack width={'100%'} height={'100%'}>
                    <VStack mt={7} maxW={'60%'} spacing={4}>
                        <Text fontSize={'lg'} textAlign={'center'} fontWeight={'bold'}>{t("No sources uploaded")}</Text>
                        <Text fontSize={14.5} color={'gray'} textAlign={'center'}>{t("Sources are referenced and used to help generate the writing")}</Text>
                    </VStack>
                </VStack> :
                <VStack width={'100%'} height={'100%'}>
                    {generation.files.map((file, index) => (
                    <SideBarFileUploadButtonOld
                        key={index}
                        icon={ImFilePdf}
                        label={file.name}
                    />
                    ))}
                </VStack>
            }

            <Button
                loadingText="Submitting"
                size="md"
                isLoading={false}
                onClick={() => {
                    onOpenGeneratePromptScreen2()
                    dispatch(setIsUploadSourcesModalOpen(true))
                }}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                _hover={{
                    bgGradient: 'linear(to-r, red.400,pink.400)',
                    boxShadow: 'xl',
                }}>
                {t("Upload Sources")}
            </Button>

            <GeneratePromptScreen2Old isOpenGeneratePromptScreen2={isOpenGeneratePromptScreen2}
                                   onCloseGeneratePromptScreen2={onCloseGeneratePromptScreen2}
                                   generation={generation}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
        </VStack>
    );
};
