import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import PDFPage from "./PDFPage";
import {io} from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";
import {SERVER_IP} from "../../utils/constants";
import {getSessionToken, getSessionTokenGeneration} from "../../utils/pdf-page-helpers";
import {useAuth} from "../../redux/AuthProvider";
import {
    setCurrentGenerationWordCount, setDocumentsGeneration,
    setFilesInDocumentGeneration,
    setHighlightsInDocument,
    setMessagesInDocument,
    setMessagesInDocumentGeneration
} from "../../redux/reducers";
import {MySpinnerLarge} from "../../components/Reuseable/MySpinner";
import PDFPageGeneration from "./PDFPageGeneration";
import { v4 as uuidv4 } from 'uuid';
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import PDFPageGenerationOld from "./PDFPageGenerationOld";
import {fetchGenerations} from "../../utils/redux-helpers";

export default function WrapperComponentGenerationOld ()  {
    const auth = useAuth();
    const toast = useToast();
    const [socketGeneration, setSocketGeneration] = useState(null);
    const { id } = useParams();
    const [sessionTokenGeneration, setSessionTokenGeneration] = useState("")
    const [currentGeneration, setCurrentGeneration] = useState(null);

    const generations = useSelector(state => state.documents.savedGenerations)
    // const documents = auth.isLoggedIn ? savedDocuments : auth.playgroundDocuments;

    const [forceRender, setForceRender] = useState(0);
    const [socketIDGeneration, setSocketIDGeneration] = useState("");
    const { isTypingGeneration, setIsTypingGeneration } = useAuth();
    const {isThinkingGeneration, setIsThinkingGeneration} = useAuth();
    const dispatch = useDispatch();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();

    useEffect( () => {
        console.log("In UseEffect WrapperComponentGeneration with id:", id);
        const newSocketGeneration = io(SERVER_IP);
        setSocketGeneration(newSocketGeneration);
        console.log("Socket Generation:", socketGeneration)
        newSocketGeneration.on('connected', function(data) {
            console.log("Connected Generation:", data);
            setSocketIDGeneration(newSocketGeneration.id);
        });
        return () => {
            console.log("SocketGeneration disconnecting", newSocketGeneration)
            newSocketGeneration.disconnect();
        }
    }, [id])

    useEffect(() => {
        console.log("In UseEffect WrapperComponentGeneration with socket:", socketGeneration);
        console.log(generations)

        fetchGenerations(localStorage.getItem("userID")).then(r => {
            console.log("fetch generations in home page here", r)
            if (r === undefined) {
                dispatch(setDocumentsGeneration([]));
            } else {
                dispatch(setDocumentsGeneration(r.generations));
                if (generations.length > 0) {
                    console.log("Generations length > 0")
                    setCurrentGeneration(generations.find((generation) => generation.id === id));
                    setForceRender(prevForceRender => prevForceRender + 1);
                }
            }
        })
    }, [generations.length, id]);

    useEffect(() => {
        console.log("In UseEffect WrapperComponentGeneration with currentGeneration:", currentGeneration);
        if (currentGeneration) {
            console.log("CurrentGeneration is not null")
            const fetchSessionTokenGeneration = async () => {
                const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
                console.log("USERID", userID)
                const response = await getSessionTokenGeneration(id, userID);
                if (response) {
                    console.log("RESPONSE FROM GET GENERATION", response);  // START HERE
                    // if (response.doc_metadata.highlights) {
                    //     dispatch(setHighlightsInDocument({documentID: currentGeneration.id,
                    //         newHighlights: response.doc_metadata.highlights.slice().reverse()}));
                    // }
                    if (response.messages) {
                        if (response.messages.length > 0) {
                            dispatch(setCurrentGenerationWordCount(response.messages[0].word_count));
                        }
                        dispatch(setMessagesInDocumentGeneration({documentID: currentGeneration.id, newMessages: response.messages}));
                    }
                    if (response.files) {
                        // response.files is a list of objects with keys: id, name, url
                        // {
                        //     "id": "b4d5a8a1-9c8a-4c6a-9e6c-6c1b3f0e2b4b",
                        //     "name": "test.pdf",
                        //     "url": "https://s3.amazonaws.com/pdfgeneration/test.pdf"
                        // }
                        // response.files is a list of strings. Loop through and create objects with keys: id, name, url. Make id and url equal to
                        // "" for now. Just set name equal to the string.
                        const files = response.files.map((file) => {
                            return {
                                id: file.file_id,
                                name: file.file_name,
                                url: file.url
                            }
                        });
                        dispatch(setFilesInDocumentGeneration({documentID: currentGeneration.id, newFiles: files}));
                    }
                    setSessionTokenGeneration(response.id);
                }
            };
            fetchSessionTokenGeneration().then(r => {});
        }
    }, [currentGeneration]);

    return (
        sessionTokenGeneration === "" ?
            <div style={{justifyContent:'center', alignItems:'center', display:'flex', height:'100%', width:'100%', paddingTop:"5rem"}}>
                <MySpinnerLarge/>
            </div>
            :
            <div>
                <PDFPageGenerationOld documentGeneration={generations.find((generation) => generation.id === id)}
                                   key={forceRender}
                                   documentsGeneration={generations}
                                   idGeneration={id}
                                   isLoggedInGeneration={auth.isLoggedIn}
                                   sessionTokenGeneration={sessionTokenGeneration}
                                   socketIDGeneration={socketIDGeneration}
                                   socketGeneration={socketGeneration}
                                   isTypingGeneration={isTypingGeneration} setIsTypingGeneration={setIsTypingGeneration}
                                   isThinkingGeneration={isThinkingGeneration} setIsThinkingGeneration={setIsThinkingGeneration}
                                   toastGeneration={toast}/>
                <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                               onClosePricingScreen={onClosePricingScreen}
                               onOpenPricingScreen={onOpenPricingScreen}/>
            </div>
    )
}






