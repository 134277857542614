import React, {Component} from "react";
import "../../style/App.css";
import { connect } from 'react-redux';
import {
    addMessageInDocument, addToCurrentChatResponse,
    setCurrentChatClaudeID,
    setCurrentChatQuestion,
    setCurrentChatSelectionText,
    setCurrentChatType,
    setCurrentChatUserID,
    appendToMessageInDocument, updateMessageInDocument, resetCurrentChatResponse, setWhichTab
} from "../../redux/reducers";
import {FaArrowsAltH} from "react-icons/fa";
import {SidebarGeneration} from "../../components/SidebarComponents/SidebarGeneration";
import FullScreenTemplateGeneration from "../../templates/FullScreenTemplateGeneration";
import {SideBarFileUpload} from "./SideBarFileUpload";
import OnlyPDF from "./OnlyPDF";

class PDFPageGeneration extends Component {
    constructor(props) {
        super(props);
        this.pdfRef = null;
    }

    state = {
        areaSelectionEnabled: false,
        currentPage: 1,
        totalPages: 14,
        thumbnails: [],
        isRendering: false,
        renderQueue: [],
        zoomLevel: 1,
        isResizing: false,
        panelWidth: this.props.isGeneratePDFVisible ? 50 : 50,
    };

    handleMouseDown = (e) => {
        this.setState({
            isResizing: true,
            initialPos: e.clientX,
        });
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseUp = () => {
        this.setState({
            isResizing: false,
        });
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseMove = (e) => {
        if (this.state.isResizing) {
            const containerRect = this.container.getBoundingClientRect();
            const newPanelWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;

            // you can also add some conditions to set min and max width limits
            this.setState({ panelWidth: newPanelWidth });
        }
    }

    componentDidMount() {
        this.setState({
            panelWidth: this.props.isGeneratePDFVisible ? 50 : 50,
        });
    }

    render() {
        return (
            <FullScreenTemplateGeneration generationTitle={this.props.documentGeneration.name} generation={this.props.documentGeneration}>
                <div className="App" style={{ display: "flex", height: '100%',
                    width:'100%', }} ref={ref => this.container = ref}>
                    {!this.props.isGeneratePDFVisible ?
                        <div style={{
                            height: "100%",
                            width: `${this.state.panelWidth}%`,
                            // width: this.props.isGeneratePDFVisible ? `${this.state.panelWidth}%` : `${this.state.panelWidth}%`,
                            position: "relative",
                            backgroundColor: "white",
                        }}>
                            <SideBarFileUpload generation={this.props.documentGeneration} sessionTokenGeneration={this.props.sessionTokenGeneration}/>
                        </div> :
                        <div style={{
                            height: "100%",
                            // width: this.props.isChatVisible ? `${this.state.panelWidth}%` : '100%',
                            width: `${this.state.panelWidth}%`,
                            position: "relative",
                            backgroundColor: "white",
                        }}>
                            <OnlyPDF
                                generation={this.props.documentGeneration}
                                onRef={ref => this.pdfRef = ref}
                            />
                        </div>}


                    {/*DIVIDER*/}
                    <div
                        onMouseDown={this.handleMouseDown}
                        style={{
                            cursor: 'ew-resize',
                            width: '0.3rem',
                            backgroundColor: 'grey',
                            height: 'calc(100% + 3rem)',
                            position: 'relative', // Needed to position the icon
                        }}
                    >
                        <span
                            style={{
                                position: 'absolute', // Position the icon absolutely within the divider
                                top: '50%', // Center vertically
                                left: '50%', // Center horizontally
                                transform: 'translate(-50%, -50%)', // Adjust the exact centering
                                fontSize: '12px', // Adjust the size as needed
                                color: 'white', // Color of the icon, change as needed
                                userSelect: 'none', // Prevent text selection
                            }}
                        >
                            <FaArrowsAltH size={'1rem'} color={'black'}/>
                        </span>
                    </div>
                    {/*DIVIDER*/}
                    {/*SIDE BAR*/}
                    {this.props.isChatVisible ?
                        <SidebarGeneration
                            documentGeneration={this.props.documentGeneration}
                            sessionTokenGeneration={this.props.sessionTokenGeneration}
                            width={100 - this.state.panelWidth}
                            socketIDGeneration={this.props.socketIDGeneration}
                            socketGeneration={this.props.socketGeneration}
                            pdfRef={this.pdfRef}
                        /> : null}
                    {/*SIDE BAR*/}
                </div>
            </FullScreenTemplateGeneration>
        )
    }
}

const mapStateToProps = state => ({
    isChatVisible: state.settings.isChatVisible,
    isGeneratePDFVisible: state.settings.isGeneratePDFVisible,
    currentChatUserIDGeneration: state.settings.currentChatUserIDGeneration,
    currentChatClaudeIDGeneration: state.settings.currentChatClaudeIDGeneration,
    currentChatSelectionTextGeneration: state.settings.currentChatSelectionTextGeneration,
    currentChatQuestionGeneration: state.settings.currentChatQuestionGeneration,
    currentChatTypeGeneration: state.settings.currentChatTypeGeneration,
    currentChatResponseGeneration: state.settings.currentChatResponseGeneration,
});

const mapDispatchToProps = {
    addMessageInDocument,
    appendToMessageInDocument,
    updateMessageInDocument,
    setCurrentChatQuestion,
    setCurrentChatUserID,
    setCurrentChatClaudeID,
    setCurrentChatSelectionText,
    setCurrentChatType,
    addToCurrentChatResponse,
    resetCurrentChatResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFPageGeneration);
