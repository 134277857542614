import React, { useState } from "react";
import {
    Box, useDisclosure, useToast
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import {
    addMessageInDocumentGeneration,
    addToCurrentChatResponseGeneration,
    appendToMessageInDocumentGeneration,
    removeMessageInDocumentGeneration,
    resetCurrentChatResponseGeneration,
    setCurrentChatClaudeIDGeneration,
    setCurrentChatQuestionGeneration,
    setCurrentChatTypeGeneration,
    setCurrentChatUserIDGeneration,
    setIsPricingModalOpen,
    updateMessageInDocumentGeneration
} from "../../redux/reducers";
import { v4 as uuidv4 } from 'uuid';
import {useAuth} from "../../redux/AuthProvider";
import {Generation} from "./Generation";
import GeneratePromptScreen1 from "../Modals/Generation/GeneratePromptScreen1";
import {getClaudeResponse, postChatPair} from "../../utils/api";
import {postChatPairMultidoc} from "../../utils/multi-doc-helpers";
import {useTranslation} from "react-i18next";
import {createMultidocSession, multidocChat} from "../../utils/multi-doc-helpers";

// handleChatResponseNoSelection = async (type, pageNumber, sessionToken, socketID, question)
export function SidebarGeneration({ documentGeneration, sessionTokenGeneration, width, socketIDGeneration, socketGeneration, pdfRef}) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const currentChatUserIDGeneration = useSelector(state => state.settings.currentChatUserIDGeneration);
    const currentChatTypeGeneration = useSelector(state => state.settings.currentChatTypeGeneration);
    const currentChatSelectionTextGeneration = useSelector(state => state.settings.currentChatSelectionTextGeneration);
    const currentChatQuestionGeneration = useSelector(state => state.settings.currentChatQuestionGeneration);
    const currentChatClaudeIDGeneration = useSelector(state => state.settings.currentChatClaudeIDGeneration);
    const currentChatResponseGeneration = useSelector(state => state.settings.currentChatResponseGeneration);
    const generations = useSelector(state => state.documents.savedGenerations);
    // const currentMessagesGeneration = generations.find(doc => doc.id === documentGeneration.id).messages;

    const { isTypingGeneration, setIsTypingGeneration } = useAuth();
    const {isThinkingGeneration, setIsThinkingGeneration} = useAuth();
    const toast = useToast();
    const auth = useAuth();
    const { isOpen: isOpenGeneratePromptScreen1, onOpen: onOpenGeneratePromptScreen1, onClose: onCloseGeneratePromptScreen1 } = useDisclosure();
    const currentGenerationSessionToken = useSelector(state => state.settings.currentGenerationSessionToken);
    // const [intervalHook, setIntervalHook] = useState(0)

    // // type, message_responding_to, follow_up_question, sessionToken, socketID
    // const handleNewMessageGeneration = (chatInputGeneration) => {
    //     handleChatResponseNoSelection('general_question', null, sessionTokenGeneration, socketIDGeneration, chatInputGeneration);
    //     dispatch(setCurrentChatInputGeneration(''));
    // };

    // finish adding in the true and false for isThinking and isTyping later

    const getMessageHistory = () => {
        let message_history = []
        const message_history_length = documentGeneration.messages.length;

        // If message_history_length is 0, leave message_history as an empty array
        if (message_history_length > 0) {
            // Determine how many messages to include in the history
            let messageLimit;
            if (message_history_length === 2) {
                messageLimit = 2; // Last 2 messages for message_history_length of 2
            } else if (message_history_length === 4) {
                messageLimit = 4; // Last 4 messages for message_history_length of 4
            } else if (message_history_length > 4) {
                messageLimit = 4; // Most recent 4 messages for message_history_length greater than 4
            } else {
                messageLimit = message_history_length; // This handles any case less than 2, though not explicitly required by the spec
            }

            // Iterate over the messages to populate message_history accordingly
            for (let i = message_history_length - 1; i >= message_history_length - messageLimit; i--) {
                const message = documentGeneration.messages[i];
                if (message.user === 'User') {
                    message_history.push({
                        user: "User",
                        response: message.question || '',
                        type: 'general_multidoc',
                        id: message.id,
                        selection_text: null,
                    });
                } else {
                    message_history.push({
                        user: "Assistant",
                        response: message.response || '',
                        id: message.id,
                    });
                }
            }
            console.log("MESSAGE LIMIT", messageLimit);
        }
        console.log("MESSAGE HISTORY LENGTH", message_history.length)
        console.log("MESSAGE HISTORY", message_history);
        return message_history;
    }

    const startGeneration = async (prompt, sliderValue, shouldReferenceSources) => {
        const { userGenerationID, claudeGenerationID } = handleMessageCreation("general_multidoc", prompt, sliderValue, shouldReferenceSources);
        const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111";

        dispatch(resetCurrentChatResponseGeneration());
        dispatch(setCurrentChatUserIDGeneration(userGenerationID));
        dispatch(setCurrentChatClaudeIDGeneration(claudeGenerationID));
        dispatch(setCurrentChatQuestionGeneration(prompt));
        dispatch(setCurrentChatTypeGeneration("general_multidoc"));
        setIsThinkingGeneration(true);
        setIsTypingGeneration(false);
        // let sessionToken = sessionTokenGeneration;
        // console.log("SESSION TOKEN RIGHT BEFORE CHAT", currentGenerationSessionToken)
        // if (currentGenerationSessionToken === "") {
        //     const res = await createMultidocSession(documentGeneration.id, userID);
        //     let sessionToken = res.multidocSessionToken;
        //     dispatch(setCurrentGenerationSessionToken(sessionToken));
        // }

        //export const multidocChat = async (user_id, generation_session_token, prompt, word_count, should_reference_sources,
        //                                       socketID, toast) => {
        const messageHistory = getMessageHistory();
        multidocChat(userID, sessionTokenGeneration, prompt, socketIDGeneration, toast, messageHistory).then(response => {
            if (response !== null) {
                if (response.status === 402) {
                    console.log("pricing_modal_shown")
                    socketGeneration.off('receive_message');
                    console.log("Claude response PAID PLAN:", response.response)
                    localStorage.setItem("isChatExceeded", "true");
                    setIsPricingModalOpen(true);
                    setIsTypingGeneration(false);
                    setIsThinkingGeneration(false);
                    dispatch(removeMessageInDocumentGeneration({ messageID: userGenerationID, documentID: documentGeneration.id }));
                    dispatch(removeMessageInDocumentGeneration({ messageID: claudeGenerationID, documentID: documentGeneration.id }));
                }
                else {
                    if (isTypingGeneration || !isTypingGeneration) {
                        setIsTypingGeneration(false);
                        setIsThinkingGeneration(false);
                        socketGeneration.off('receive_message');
                        if (response.response !== "") {
                            // console.log("MULTIDOC Chat response:", response)
                            // console.log("updateMessage payload", {
                            //     messageID: claudeGenerationID, type: "general_multidoc", user: 'Gemini', question: prompt,
                            //     response: response.response, documentID: documentGeneration.id
                            // })
                            dispatch(updateMessageInDocumentGeneration({ messageID: claudeGenerationID, type: "general_multidoc",
                                user: 'Gemini', question: prompt, response: response.response, documentID: documentGeneration.id }));

                            // console.log("Claude response SESSION EXPIRED:", responseText) // "Claude response SESSION EXPIRED:" "undefined"
                            postChatPairMultidoc([
                                { id: userGenerationID, type: "general_multidoc", user: 'User', selection_text: null, question: prompt },
                                { id: claudeGenerationID, type: "general_multidoc", user: 'Gemini', selection_text: null, question: prompt, response: response.response }
                            ], localStorage.getItem("userID"), documentGeneration.id, auth.isLoggedIn);
                        }
                    }
                }
            } else {
                // Response is NULL
                socketGeneration.off('receive_message');
                setIsTypingGeneration(false);
                setIsThinkingGeneration(false);
                dispatch(removeMessageInDocumentGeneration({ messageID: userGenerationID, documentID: documentGeneration.id }));
                dispatch(removeMessageInDocumentGeneration({ messageID: claudeGenerationID, documentID: documentGeneration.id }));
            }

        }).catch(error => {
            socketGeneration.off('receive_message');
            console.error("Error getting Claude response:", error);
        });


        socketGeneration.on('receive_message', function(data) {
            // console.log("MULTIDOC message from Claude:", data.message)
            setIsThinkingGeneration(false);
            setIsTypingGeneration(true);
            addToCurrentChatResponseGeneration(data.message);
            dispatch(appendToMessageInDocumentGeneration({ messageID: claudeGenerationID, type: "general_multidoc", user: 'Gemini',
                selection_text: "", question: prompt, response: data.message, documentID: documentGeneration.id }));
        });
    };

    // const checkGenerationProgress = async (userID, sessionToken, claudeGenerationID, celeryTaskID) => {
    //     const interval = setInterval(async () => {
    //         const response = await axios.post(SERVER_IP + '/pdf/generation_progress', {
    //             "user_id": userID.toString(),
    //             "generation_session_token": sessionToken.toString(),
    //             "task_id": celeryTaskID.toString(),
    //         });
    //         console.log("response from /pdf/generation_progress", response);
    //         if (response.data.status === 200) {
    //             console.log("RETURNED CONTENT", response.data.message.generation_content);  // IMPORTANT LINE
    //             const responseText = response.data.message.generation_content;
    //
    //             // setIsTypingGeneration(false);
    //             // setIsThinkingGeneration(false);
    //             // socketGeneration.off('receive_message');
    //             // dispatch(updateMessageInDocumentGeneration({ messageID: claudeGenerationID, response: responseText,
    //             //     documentID: documentGeneration.id }));
    //             clearInterval(interval);
    //             // else {
    //             //     if (!responseText === undefined) {
    //             //         dispatch(updateMessageInDocumentGeneration({ messageID: claudeGenerationID, response: "",
    //             //             documentID: documentGeneration.id }));
    //             //     }
    //             // }
    //         } else if (response.data.status === 205) {
    //             console.log("still processing");
    //         } else if (response.data.status === 401) {
    //             toast({
    //                 title: t("Oops, something went wrong. Please try again."),
    //                 status: "error",
    //                 duration: null,
    //                 isClosable: true,
    //             });
    //             clearInterval(interval);
    //             setIsTypingGeneration(false);
    //             setIsThinkingGeneration(false);
    //             socketGeneration.off('receive_message');
    //         }
    //     }, 4000);
    //     setIntervalHook(interval);
    // };

    const handleMessageCreation = (type, question, wordCount, shouldReferenceSources) => {
        const userIDHere = uuidv4();
        const claudeID = uuidv4();
        socketGeneration.off('receive_message');
        const userMessage = {
            id: userIDHere,
            type: type,
            user: 'User',
            question: question,
            // word_count: wordCount,
            // should_reference_sources: shouldReferenceSources,
        };
        const claudeMessage = {
            id: claudeID,
            type: type,
            user: 'Gemini',
            question: question,
            // word_count: wordCount,
            // should_reference_sources: shouldReferenceSources,
            response: ""
        };
        dispatch(addMessageInDocumentGeneration({ messageToAdd: userMessage, documentID: documentGeneration.id }));
        dispatch(addMessageInDocumentGeneration({ messageToAdd: claudeMessage, documentID: documentGeneration.id }));
        return { userGenerationID: userIDHere, claudeGenerationID: claudeID };
    }

    const stopClaudeResponse = () => {
        setIsThinkingGeneration(false);
        setIsTypingGeneration(false);
        socketGeneration.off('receive_message');
        postChatPair([
            {
                id: currentChatUserIDGeneration, type: currentChatTypeGeneration, user: 'User',
                selection_text: currentChatSelectionTextGeneration, question: currentChatQuestionGeneration
            },
            {
                id: currentChatClaudeIDGeneration, type: currentChatTypeGeneration, user: 'Gemini',
                selection_text: currentChatSelectionTextGeneration, question: currentChatQuestionGeneration,
                response: currentChatResponseGeneration
            }
        ], localStorage.getItem("userID"),  documentGeneration.id, auth.isLoggedIn).then(r =>{
            dispatch(resetCurrentChatResponseGeneration())
        });
    }

    return (
        <Box width={`${width}%`} height="100%" display="flex" flexDirection="column">
            <Generation theDocumentGeneration={documentGeneration}
                        sessionTokenGeneration={sessionTokenGeneration} socketIDGeneration={socketIDGeneration}
                        stopClaudeResponseGeneration={stopClaudeResponse} startGeneration={startGeneration} pdfRef={pdfRef}/>
            <GeneratePromptScreen1 isOpenGeneratePromptScreen1={isOpenGeneratePromptScreen1} onCloseGeneratePromptScreen1={onCloseGeneratePromptScreen1}/>
        </Box>
    );
}
