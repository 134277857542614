import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Input,
    Text,
    Tooltip,
    Grid,
    VStack,
    Button, HStack, Badge, useBreakpointValue, CloseButton
} from '@chakra-ui/react';
import { FaBars, FaChevronUp, FaChevronDown, FaExpand } from 'react-icons/fa';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { FiChevronDown, FiChevronUp, FiMinimize, FiZoomIn, FiZoomOut } from 'react-icons/fi';
import lightTheme from "../../utils/styles";
import { CustomIconButton } from "../../components/buttons/CustomIconButton";
import { SiPowerpages } from "react-icons/si";
import { useTranslation } from "react-i18next";
import { setScanInProgress, setIsGeneratePDFVisible } from "../../redux/reducers";
import { useDispatch } from "react-redux";
import { SideBarFileUploadButton } from "./SideBarFileUploadButton";
import { ImFilePdf } from "react-icons/im";
import { setIsViewSourcesModalOpen } from "../../redux/reducers";
import { useSelector } from "react-redux";


const PDFPageHeaderGeneration = ({
    currentPage, totalPages, zoomLevel, areaSelectionEnabled,
    jumpToPage, onPageChanged, toggleAreaSelection, isFullScreen,
    toggleZoomLevelOut, toggleZoomLevelIn, generation
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base';
    const dispatch = useDispatch();
    const isViewSourcesModalOpen = useSelector(state => state.settings.isViewSourcesModalOpen);

    const [componentWidth, setComponentWidth] = useState(0);
    const headerRef = useRef(null);

    useEffect(() => {
        if (!headerRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setComponentWidth(entry.contentRect.width);
            }
        });

        resizeObserver.observe(headerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <Box
            ref={headerRef}
            backgroundColor={lightTheme.colors.lightGray}
            width={"100%"}
            height={"3rem"}
            overflow={"auto"}
            justifyContent={'space-between'}
            alignItems={'center'}
            display={'flex'}
            px={'1rem'}
        >
            <HStack spacing={2}>
                <CustomIconButton
                    icon={FiChevronUp}
                    size={25}
                    ariaLabel="Arrow Up"
                    onClick={() => {
                        if (currentPage > 1) {
                            jumpToPage(currentPage - 1);
                        }
                    }}
                />
                <Input
                    placeholder={t("Go to page")}
                    backgroundColor={lightTheme.colors.white}
                    // width={"4.75rem"}
                    width={!isMobile ? "3.9rem" : "3.5rem"}
                    px={3}
                    type="number"
                    min={1}
                    max={totalPages}
                    value={currentPage}
                    onChange={(e) => {
                        onPageChanged({
                            currentPage: e.target.value,
                            totalPages: totalPages
                        })
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            jumpToPage(currentPage);
                        }
                    }}
                />
                <Box ml={-0.5}>
                    <CustomIconButton
                        icon={FiChevronDown}
                        size={25}
                        ariaLabel="Arrow Down"
                        onClick={() => {
                            if (currentPage < totalPages) {
                                jumpToPage(currentPage + 1);
                            }
                        }}
                    />
                </Box>
            </HStack>









            <Tooltip hasArrow label={t("View Sources")} bg='black' color="white">
                {/* <Box position="relative" display="inline-block">
                    <button onClick={toggleAreaSelection} style={{
                        backgroundColor: areaSelectionEnabled ? 'blue' : lightTheme.colors.lightGray,
                        color: areaSelectionEnabled ? 'white' : 'black',
                        padding: '10px',
                        _hover: {backgroundColor: lightTheme.colors.darkGray},
                        borderColor: 'lightgray',
                        borderWidth: areaSelectionEnabled ? '0px' : '0.5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        position: "relative",
                        height: '80%',
                        fontSize: 'small',
                    }}>
                        {t("Image Select")}
                    </button>
                </Box> */}
                <Menu placement="bottom" onClose={() => {
                    dispatch(setIsViewSourcesModalOpen(false));
                }} isOpen={isViewSourcesModalOpen}>
                    <MenuButton
                        as={Button}
                        rightIcon={!isViewSourcesModalOpen ? <FiChevronDown /> : <FiChevronUp />}
                        bg={lightTheme.colors.lightGray}
                        color={'black'}
                        size="sm"
                        _hover={{ bg: lightTheme.colors.darkGray }}
                        _active={{ bg: lightTheme.colors.darkGray }}
                        borderWidth={'0.5px'}
                        borderColor="gray"
                        fontWeight={'bold'}
                        onClick={() => {
                            dispatch(setIsViewSourcesModalOpen(!isViewSourcesModalOpen));
                        }}
                    >
                        {t("View Sources")}
                    </MenuButton>
                    <MenuList zIndex={999} width={`${componentWidth * (componentWidth > 750 ? 0.66 : 0.77)}px`}
                        borderColor={'lightgray'} borderWidth={0.25}
                        shadow={`1.5px 1.8px 0px lightgray`}
                        borderRadius={'0.5rem'}>
                        <Box maxHeight="38rem" overflowY="auto">
                            <VStack width={'100%'} height={'100%'}>
                                {generation.files.map((file, index) => (
                                    <SideBarFileUploadButton
                                        key={index}
                                        icon={ImFilePdf}
                                        label={file.name}
                                        file={file}
                                        generation={generation}
                                    />
                                ))}
                                {/* up arrow */}
                            </VStack>
                        </Box>
                    </MenuList>
                </Menu>
            </Tooltip>









            <HStack spacing={!isMobile ? 2 : 1}>
                <CustomIconButton
                    icon={FiZoomOut}
                    size={18}
                    ariaLabel="Zoom Out"
                    onClick={() => {
                        toggleZoomLevelOut();
                    }}
                />
                <Text>
                    {Math.round(zoomLevel * 100)}%
                </Text>
                <CustomIconButton
                    icon={FiZoomIn}
                    size={18}
                    ariaLabel="Zoom In"
                    onClick={() => {
                        toggleZoomLevelIn();
                    }}
                />
                <CloseButton
                    size="md"
                    mr={-1}
                    onClick={() => {
                        dispatch(setIsGeneratePDFVisible(false));
                    }}
                    bg="transparent"
                    borderRadius="50%"
                    _hover={{
                        bg: "gray.200",
                        borderRadius: "50%",
                    }}
                />
                {/*{isFullScreen && !isMobile ?*/}
                {/*    <CustomIconButton*/}
                {/*        icon={FiMinimize}*/}
                {/*        size={20}*/}
                {/*        ariaLabel="Minimize Screen"*/}
                {/*        onClick={() => {*/}
                {/*            toggleIsFullScreen();*/}
                {/*        }}*/}
                {/*    /> :*/}
                {/*    !isMobile ?*/}
                {/*        <CustomIconButton*/}
                {/*            icon={FaExpand}*/}
                {/*            size={20}*/}
                {/*            ariaLabel="Maximize Screen"*/}
                {/*            onClick={() => {*/}
                {/*                toggleIsFullScreen();*/}
                {/*            }}*/}
                {/*        /> : null}*/}
            </HStack>






        </Box>
    );
};

export default PDFPageHeaderGeneration;
