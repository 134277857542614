import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import PDFPage from "./PDFPage";
import {io} from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";
import {SERVER_IP} from "../../utils/constants";
import {SERVER_IP_MULTIDOC} from "../../utils/constants";
import {getSessionToken, getSessionTokenGeneration} from "../../utils/pdf-page-helpers";
import {useAuth} from "../../redux/AuthProvider";
import {
    setCurrentGenerationSessionToken,
    setCurrentGenerationWordCount,
    setFilesInDocumentGeneration,
    setHighlightsInDocument,
    setMessagesInDocument,
    setMessagesInDocumentGeneration
} from "../../redux/reducers";
import {MySpinnerLarge} from "../../components/Reuseable/MySpinner";
import PDFPageGeneration from "./PDFPageGeneration";
import { v4 as uuidv4 } from 'uuid';
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import {addActiveSourcesMultidoc, createMultidocSession, getMultidoc} from "../../utils/multi-doc-helpers";
import {setCurrentGeneratePDF, setCurrentGeneratePDFPage, setIsGeneratePDFVisible} from "../../redux/reducers";
import { motion, AnimatePresence } from "framer-motion";

export default function WrapperComponentGeneration ()  {
    const auth = useAuth();
    const toast = useToast();
    const [socketGeneration, setSocketGeneration] = useState(null);
    const { id } = useParams();
    const [sessionTokenGeneration, setSessionTokenGeneration] = useState("")
    const [currentGeneration, setCurrentGeneration] = useState(null);

    const generations = useSelector(state => state.documents.savedGenerations)
    // const documents = auth.isLoggedIn ? savedDocuments : auth.playgroundDocuments;

    const [forceRender, setForceRender] = useState(0);
    const [socketIDGeneration, setSocketIDGeneration] = useState("");
    const { isTypingGeneration, setIsTypingGeneration } = useAuth();
    const {isThinkingGeneration, setIsThinkingGeneration} = useAuth();
    const dispatch = useDispatch();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();

    const [loadingStep, setLoadingStep] = useState(0);
    const loadingMessages = [
        "We're preparing your workspace...",
        "We're loading up your documents...",
        "Putting some final touches so you're ready to go!"
    ];

    useEffect( () => {
        console.log("In UseEffect WrapperComponentGeneration with id:", id);
        const newSocketGeneration = io(SERVER_IP_MULTIDOC);
        setSocketGeneration(newSocketGeneration);
        console.log("Socket Generation:", socketGeneration)
        newSocketGeneration.on('connected', function(data) {
            console.log("Connected Generation:", data);
            setSocketIDGeneration(newSocketGeneration.id);
        });
        return () => {
            console.log("SocketGeneration disconnecting", newSocketGeneration)
            newSocketGeneration.disconnect();
        }
    }, [id])

    useEffect(() => {
        console.log("In UseEffect WrapperComponentGeneration with socket:", socketGeneration);
        // console.log(generations)

        if (generations.length > 0) {
            console.log("Generations length > 0")
            setCurrentGeneration(generations.find((generation) => generation.id === id));
            setForceRender(prevForceRender => prevForceRender + 1);
        }
    }, [generations.length, id]);

    useEffect(() => {
        console.log("In UseEffect WrapperComponentGeneration with currentGeneration:", currentGeneration);
        if (currentGeneration) {
            console.log("CurrentGeneration is not null")
            const fetchSessionTokenGeneration = async () => {
                const start = new Date().getTime() / 1000;
                const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
                console.log("USERID", userID)
                await createMultidocSession(id, userID).then(createResponse => {
                    console.log("RESPONSE FROM CREATE MULTIDOC SESSION", createResponse);
                    const end1 = new Date().getTime() / 1000;
                    const duration1 = end1 - start;
                    console.log("DURATION OF CREATE SESSION RIGHT AWAY", duration1);
                    return getMultidoc(id, userID).then(getResponse => ({
                        createResponse,
                        getResponse,
                    }));
                }).then(({ createResponse, getResponse }) => {
                    if (getResponse) {
                        console.log("RESPONSE FROM GET MULTIDOC", getResponse);  // START HERE
                        if (getResponse.messages) {
                            dispatch(setMessagesInDocumentGeneration({documentID: currentGeneration.id, newMessages: getResponse.messages}));
                        }
                        if (getResponse.files) {
                            const files = getResponse.files.map((file) => {
                                return {
                                    id: file.file_id,
                                    name: file.file_name,
                                    url: file.url,
                                    isActive: true,
                                }
                            });
                            dispatch(setFilesInDocumentGeneration({documentID: currentGeneration.id, newFiles: files}));
                            console.log("MULTIDOC SESSION TOKEN DEBUGGING", createResponse.multidocSessionToken)
                            
                            const startActive = new Date().getTime() / 1000;
                            addActiveSourcesMultidoc(createResponse.multidocSessionToken, localStorage.getItem("userID"),
                                files.map((file) => file.id)).then(r => {
                                    const endActive = new Date().getTime() / 1000;
                                    const durationActive = endActive - startActive;
                                    console.log("DURATION OF ADD ACTIVE SOURCES", durationActive);
                                });

                            if (getResponse.files.length > 0) {
                                dispatch(setCurrentGeneratePDF(getResponse.files[0].url));
                                dispatch(setCurrentGeneratePDFPage(1));
                                dispatch(setIsGeneratePDFVisible(true));
                            }
                        }
                        setSessionTokenGeneration(createResponse.multidocSessionToken);
                        const end2 = new Date().getTime() / 1000;
                        const duration2 = end2 - start;
                        console.log("DURATION OF CREATE SESSION LATER", duration2);
                        dispatch(setCurrentGenerationSessionToken(createResponse.multidocSessionToken));
                    }
                });
            };
            fetchSessionTokenGeneration().then(r => {});
        }
    }, [currentGeneration]);

    useEffect(() => {
        if (sessionTokenGeneration === "") {
            const timer = setInterval(() => {
                setLoadingStep((prev) => (prev + 1) % loadingMessages.length);
            }, 3000); // Change message every 3 seconds
            return () => clearInterval(timer);
        }
    }, [sessionTokenGeneration]);

    const LoadingScreen = () => (
        <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            paddingTop: "0rem",
            background: 'linear-gradient(135deg, #f6f8ff 0%, #f1f5ff 100%)'
        }}>
            <motion.div
                animate={{
                    scale: [1, 1.2, 1],
                }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                }}
            >
                <div style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    border: '3px solid transparent',
                    borderTopColor: '#3182ce',
                    borderBottomColor: '#3182ce',
                    animation: 'spin 1.5s linear infinite'
                }}>
                    <style>
                        {`
                            @keyframes spin {
                                0% { transform: rotate(0deg); }
                                100% { transform: rotate(360deg); }
                            }
                        `}
                    </style>
                </div>
            </motion.div>
            
            <AnimatePresence mode='wait'>
                <motion.div
                    key={loadingStep}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    style={{
                        marginTop: '4rem',
                        marginBottom: '1rem',
                        fontSize: '1.25rem',
                        fontWeight: '500',
                        color: '#2d3748',
                        textAlign: 'center',
                        maxWidth: '80%'
                    }}
                >
                    {loadingMessages[loadingStep]}
                </motion.div>
            </AnimatePresence>
            
            <motion.div
                animate={{
                    opacity: [0.5, 1, 0.5],
                }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                }}
                style={{
                    marginTop: '1rem',
                    display: 'flex',
                    gap: '0.5rem'
                }}
            >
                {[...Array(3)].map((_, i) => (
                    <div
                        key={i}
                        style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: i === loadingStep ? '#3182ce' : '#CBD5E0'
                        }}
                    />
                ))}
            </motion.div>
        </div>
    );

    return (
        sessionTokenGeneration === "" ?
            // <div style={{justifyContent:'center', alignItems:'center', display:'flex', height:'100%', width:'100%', paddingTop:"5rem"}}>
            //     <MySpinnerLarge/>
            // </div>
            <LoadingScreen/>
            :
            <div>
                <PDFPageGeneration documentGeneration={generations.find((generation) => generation.id === id)}
                                   key={forceRender}
                                   documentsGeneration={generations}
                                   idGeneration={id}
                                   isLoggedInGeneration={auth.isLoggedIn}
                                   sessionTokenGeneration={sessionTokenGeneration}
                                   socketIDGeneration={socketIDGeneration}
                                   socketGeneration={socketGeneration}
                                   isTypingGeneration={isTypingGeneration} setIsTypingGeneration={setIsTypingGeneration}
                                   isThinkingGeneration={isThinkingGeneration} setIsThinkingGeneration={setIsThinkingGeneration}
                                   toastGeneration={toast}/>
                <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                               onClosePricingScreen={onClosePricingScreen}
                               onOpenPricingScreen={onOpenPricingScreen}/>
            </div>
    )
}







