import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Grid,
    GridItem,
    VStack,
    Text,
    HStack,
    useDisclosure, useToast, Button, useBreakpointValue, Image, Icon,
} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";

const DocumentGridGenerationEmptyOld = () => {
    const {t} = useTranslation();

    return (
        <VStack width={['95%','88%', '78%', '68%', '58%']}>
            <VStack spacing={4} width={'100%'} bg={'white'}>
                <Text fontSize={18} fontWeight={'bold'}>{t("No documents yet")}</Text>
                <Text color={"gray.700"}>{t("Start a new document to generate writing with AI.")}</Text>
                <Text color={"gray.700"}>{t("AI will generate tailored, long-form writing based on your uploaded sources.")}</Text>
            </VStack>
        </VStack>
    );
};

export default DocumentGridGenerationEmptyOld;