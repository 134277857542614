import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Grid,
    GridItem,
    VStack,
    Text,
    HStack,
    useDisclosure, useToast, Button, useBreakpointValue, Image, Icon,
} from '@chakra-ui/react';
import {MySpinner} from "../../components/Reuseable/MySpinner";
import MySkeletonText from "../../components/Reuseable/MySkeletonText";
import VerticalMenu from "../../components/Reuseable/VerticalMenu";
import DeleteScreen1 from "../../components/Modals/DeleteModal/DeleteScreen1";
import {deleteDocument, renameDocument} from "../../utils/home-page-helpers";
import {fetchDocuments} from "../../utils/redux-helpers";
import {
    changeNameOfDocument,
    setCurrentPageGrid,
    setCurrentPageGridGeneration,
    setDocuments
} from "../../redux/reducers";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../redux/AuthProvider";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {createThumbnail} from "../../utils/pdf-page-helpers";
import {useNavigate} from "react-router-dom";
import {FcDocument} from "react-icons/fc";
import EditGenerationTitle from "../../components/Reuseable/EditGenerationTitle";
import DeleteScreenGeneration from "../../components/Modals/DeleteModal/DeleteScreenGeneration";
import {useTranslation} from "react-i18next";

const DocumentGridGeneration = () => {
    const navigate = useNavigate();
    const { isOpen: isOpenDeleteGeneration, onOpen: onOpenDeleteGeneration, onClose: onCloseDeleteGeneration } = useDisclosure();
    const { isOpen: isEditTitleOpen, onOpen: onOpenEditTitle, onClose: onCloseEditTitle } = useDisclosure();
    const {t} = useTranslation();

    const [selectedFileID, setSelectedFileID] = useState(null);
    const [editableId, setEditableId] = useState(null);
    const [newNames, setNewNames] = useState({});
    const inputRef = useRef(null);
    const toast = useToast();
    const dispatch = useDispatch();
    const auth = useAuth();
    const generations = useSelector((state) => state.documents.savedGenerations);
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setEditableId(null);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const documentsPerPageGeneration = 25; // Set the number of documents per page
    const currentPageGridGeneration = useSelector((state) => state.settings.currentPageGridGeneration);
    const totalPagesGeneration = generations !== null ? Math.ceil(generations.length / documentsPerPageGeneration) : 0;

    // Function to calculate the slice of documents to display
    const indexOfLastDocGeneration = currentPageGridGeneration * documentsPerPageGeneration;
    const indexOfFirstDocGeneration = indexOfLastDocGeneration - documentsPerPageGeneration;
    const currentDocumentsGeneration = generations.slice(indexOfFirstDocGeneration, indexOfLastDocGeneration);

    // Function to change page
    const paginate = (pageNumber) => dispatch(setCurrentPageGridGeneration(pageNumber));

    // Function to go to the next page
    const nextPage = () => dispatch(setCurrentPageGridGeneration(Math.min(currentPageGridGeneration + 1, totalPagesGeneration)));

    // Function to go to the previous page
    const prevPage = () => dispatch(setCurrentPageGridGeneration(Math.max(currentPageGridGeneration - 1, 1)));

    const convertToDate = (timestamp) => {
        const date = new Date(timestamp);
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const day = date.getDate();
        const formattedDate = `${month} ${day}`;
        return formattedDate;
    }

    return (
        <VStack width={['95%','88%', '78%', '68%', '58%']}>
            {!isMobile ?
                <HStack justify="space-between" align="center" mt={'-4rem'} mb={'2rem'} width={'100%'} >
                    <Text fontSize={"15px"}>
                        {t("Showing")} {indexOfFirstDocGeneration + 1}-{indexOfLastDocGeneration > generations.length ? generations.length : indexOfLastDocGeneration} of {generations.length}
                    </Text>

                    <HStack spacing={3}>
                        <Button onClick={prevPage} isDisabled={currentPageGridGeneration === 1} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronLeft size={'1.23rem'}/>
                        </Button>
                        {Array.from({ length: totalPagesGeneration }, (_, i) => (
                            <Button
                                onClick={() => paginate(i + 1)}
                                key={i}
                                colorScheme={currentPageGridGeneration === i + 1 ? 'orange' : 'gray'}
                                padding={0}
                                size={'sm'}
                                borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}
                            >
                                {i + 1}
                            </Button>
                        ))}
                        <Button onClick={nextPage} isDisabled={currentPageGridGeneration === totalPagesGeneration} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronRight size={'1.23rem'}/>
                        </Button>
                    </HStack>
                </HStack> :
                <VStack justify="space-between" align="center" mt={'-4rem'} mb={'2rem'} width={'100%'} spacing={5}>
                    <Text fontSize={"15px"}>
                        {t("Showing")} {indexOfFirstDocGeneration + 1}-{indexOfLastDocGeneration > generations.length ? generations.length : indexOfLastDocGeneration} of {generations.length}
                    </Text>

                    <HStack spacing={3}>
                        <Button onClick={prevPage} isDisabled={currentPageGridGeneration === 1} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronLeft size={'1.23rem'}/>
                        </Button>
                        {Array.from({ length: totalPagesGeneration }, (_, i) => (
                            <Button
                                onClick={() => paginate(i + 1)}
                                key={i}
                                colorScheme={currentPageGridGeneration === i + 1 ? 'orange' : 'gray'}
                                padding={0}
                                size={'sm'}
                                borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}
                            >
                                {i + 1}
                            </Button>
                        ))}
                        <Button onClick={nextPage} isDisabled={currentPageGridGeneration === totalPagesGeneration} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronRight size={'1.23rem'}/>
                        </Button>
                    </HStack>
                </VStack>}
            <VStack spacing={0} width={'100%'} bg={'white'}>
                {currentDocumentsGeneration.map((generation) => (
                    <VStack spacing={0} width={'100%'}>
                        <HStack width={'100%'} _hover={{
                            cursor: 'pointer',
                            backgroundColor: 'rgb(224,247,255)',
                        }}
                                onClick={() => {
                            console.log("Clicked", generations)
                            navigate(`/multidoc/${generation.id}`)
                        }}
                                spacing={2} px={5} borderRadius={'50px'}>
                            <Icon as={FcDocument} w={6} h={6} bg={'transparent'}/>
                            <Box key={generation.id} p={'1rem'} width={'100%'}>
                                <HStack width={'100%'} spacing={4} alignItems={'center'} display={'flex'} flexDirection={'row-reverse'}
                                        justifyContent={'space-between'}>

                                    <HStack spacing={[1,5,15,25,35]}>
                                        {generation.lastOpenTime ?
                                            <Text fontSize={'sm'}>{convertToDate(generation.lastOpenTime)}</Text> :
                                            <Text fontSize={'sm'}> </Text>}
                                        {auth.isLoggedIn ?
                                            <VerticalMenu
                                                menuOptions={[
                                                    {
                                                        label: t("Rename Workspace"),
                                                        onClick: () => {
                                                            setEditableId(generation.id);
                                                            setNewNames({ ...newNames, [generation.id]: generation.name });
                                                            onOpenEditTitle();
                                                        },
                                                    },
                                                    {
                                                        label: t("Delete Workspace"),
                                                        onClick: () => {
                                                            setSelectedFileID(generation.id);
                                                            onOpenDeleteGeneration();
                                                        },
                                                    },
                                                ]}
                                            /> : null}
                                    </HStack>

                                    <VStack spacing={'0rem'} alignItems={'flex-start'}>
                                        <Text fontSize={'sm'} fontWeight={'bold'} noOfLines={1} overflow={'hidden'}
                                              maxW={['8rem','10rem','15rem','25rem','30rem']}>
                                            {/*<a href={`/generation/${generation.id}`}>*/}
                                            {/*    {generation.name}*/}
                                            {/*</a>*/}
                                            {generation.name}
                                        </Text>
                                    </VStack>
                                </HStack>
                            </Box>
                        </HStack>
                        <Box width={'92%'} height={0.25} bg={'lightgray'} ml={7}/>
                    </VStack>
                ))}
                {selectedFileID !== null ?
                    <DeleteScreenGeneration onCloseDeleteGeneration={onCloseDeleteGeneration} isOpenDeleteGeneration={isOpenDeleteGeneration}
                                            userID={localStorage.getItem("userID")}
                                   generationID={selectedFileID}/> : null}
                {editableId !== null && (
                    <EditGenerationTitle
                        isOpenEditGenerationTitle={isEditTitleOpen}
                        onCloseEditGenerationTitle={() => {
                            onCloseEditTitle();
                            setEditableId(null); // Reset editableId when modal is closed
                        }}
                        currentTitle={newNames[editableId]}
                        userID={auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"}
                        generationID={editableId}
                    />
                )}

            </VStack>
        </VStack>
    );
};

export default DocumentGridGeneration;
