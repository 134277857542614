import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
    Box,
    Button,
    useToast,
    VStack,
    Text,
    useDisclosure,
    Stack,
    Heading,
    Flex,
    Container,
    useColorModeValue,
    Icon,
    useBreakpointValue,
    InputGroup,
    Tooltip,
    InputLeftAddon, InputRightAddon, Input, HStack
} from '@chakra-ui/react';
import axios from 'axios';
import DocumentGrid from './DocumentGrid';
import {createThumbnail, initializeGeneration} from '../../utils/pdf-page-helpers';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../redux/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
    addDocument, removeDocument,
    setDocuments, setDocumentsGeneration,
    setIsUploadLoading, setScanEstimatedSeconds, setScanFileID
} from '../../redux/reducers';
import { addDocumentInStorage, getDocumentsInStorage } from '../../utils/local-storage-helpers';
import {SERVER_IP} from "../../utils/constants";
import {useDropzone} from "react-dropzone";
import { motion } from 'framer-motion';
import SignUpScreen1 from "../../components/Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../../components/Modals/SignIn/SignInScreen1";
import LargeWithNewsletter from "../../components/Reuseable/LargeWithNewsletter";
import LoadingBar from "./LoadingBar";
import FullScreenTemplateHomePage from "../../templates/FullScreenTemplateHomePage";
import {
    FcDocument, FcLock, FcQuestions, FcReading, FcSportsMode, FcViewDetails,
} from 'react-icons/fc'
import ReactPlayer from "react-player";
import lightTheme from "../../utils/styles";
import {fetchDocuments, fetchGenerations} from "../../utils/redux-helpers";
import ScanModal1 from "../../components/Modals/Scans/ScanModal1";
import ScanModalIntro1 from "../../components/Modals/Scans/ScanModalIntro1";
import DocumentGridGeneration from "./DocumentGridGeneration";
import CustomPlusIcon from "./CustomPlusIcon";
import DocumentGridGenerationEmpty from "./DocumentGridGenerationEmpty";
import FullScreenTemplateGenerationHomePage from "../../templates/FullScreenTemplateGenerationHomePage";
import {useTranslation} from "react-i18next";
import DocumentGridGenerationOld from "./DocumentGridGenerationOld";
import DocumentGridGenerationEmptyOld from "./DocumentGridGenerationEmptyOld";
// import localForage from 'localforage';
//
// // Configure localForage to use your application's name and store name
// localForage.config({
//     name: 'MyApp',
//     storeName: 'thumbnails', // name of the datastore
// });

const UnifiedHomePageGenerationOld = () => {
    const featuresRef = useRef(null);
    const testimonialsRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const fileInput = useRef();
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const { isOpen: isOpenScanModal1, onOpen: onOpenScanModal1, onClose: onCloseScanModal1 } = useDisclosure();
    const { isOpen: isOpenScanModalIntro1, onOpen: onOpenScanModalIntro1, onClose: onCloseScanModalIntro1 } = useDisclosure();
    const isUploadLoading = useSelector(state => state.settings.isUploadLoading);
    const [uploadProgress, setUploadProgress] = useState(0);
    const auth = useAuth();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const {t} = useTranslation();

    const navigateToTestimonials = () => {
        if (testimonialsRef.current) {
            const offsetTop = testimonialsRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 50; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    }

    const navigateToFeatures = () => {
        if (featuresRef.current) {
            const offsetTop = featuresRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 50; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    };

    // Select the correct document list based on authentication
    const generations = useSelector(state => state.documents.savedGenerations);

    useEffect(() => {
        const tempUserID = localStorage.getItem("userID");
        if (tempUserID !== null) {
            fetchGenerations(tempUserID).then(r => {
                console.log("fetch generations in home page here", r)

                if (r === undefined) {
                    dispatch(setDocumentsGeneration([]));
                } else {
                    dispatch(setDocumentsGeneration(r.generations));
                }
            })
        }
    }, []);

    return (
        <div>
            <FullScreenTemplateGenerationHomePage navigateToTestimonials={navigateToTestimonials} navigateToFeatures={navigateToFeatures}>
                <VStack width={'100%'} height={'100%'} justifyContent={'center'} display={'flex'} spacing={'4rem'}
                        mt={generations.length === 0 ? '0rem' : '0rem'}>
                    <VStack width={"100%"} height={'29.5rem'} mb={'1rem'} spacing={6} py={'3rem'}
                            bg={lightTheme.colors.lightGray}>
                        <VStack width={{
                            base: '95%', // 100% width on smallest screens
                            sm: '88%', // 80% width when the screen is larger than 'sm'
                            md: '78%', // 70% width when the screen is larger than 'md'
                            lg: '68%', // 60% width when the screen is larger than 'lg'
                            xl: '58%', // 50% width when the screen is larger than 'xl'
                        }} height={'23rem'} p={'0rem'} spacing={4}
                                align={'flex-start'}>
                            <Text fontSize={'lg'} fontWeight={'600'} color={'black'}>{t("Generate writing with AI")}</Text>

                            <HStack width={'100%'} spacing={7}>
                                <Box bg={'white'} height={'16rem'} width={'12.3rem'} borderWidth={'1px'} borderColor={'lightgray'}
                                     _hover={{
                                         borderColor: 'blue.400',
                                         borderWidth:'1px',
                                         cursor: 'pointer'
                                     }}
                                     onClick={() => {
                                         const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
                                         initializeGeneration("Untitled Document", userID).then(r => {
                                             navigate(`/generation/${r.id}`)
                                             window.location.reload();
                                             console.log("initialize generation here", r)
                                         });
                                     }}>
                                    <CustomPlusIcon color={"black"}/>
                                    <Text mt={2}>{t("New Document")}</Text>
                                </Box>
                                {/*<Box bg={'white'} height={'16rem'} width={'12.3rem'} borderWidth={'1px'} borderColor={'lightgray'}>*/}
                                {/*    <CustomPlusIcon color={"blue"}/>*/}
                                {/*    <VStack mt={2} align={'flex-start'} spacing={1}>*/}
                                {/*        <Text>Essay</Text>*/}
                                {/*        <Text fontSize={'sm'} color={'gray'}>asdfasdf</Text>*/}
                                {/*    </VStack>*/}
                                {/*</Box>*/}
                                {/*<Box bg={'white'} height={'16rem'} width={'12.3rem'} borderWidth={'1px'} borderColor={'lightgray'}>*/}
                                {/*    <CustomPlusIcon color={"red"}/>*/}
                                {/*    <VStack mt={2} align={'flex-start'} spacing={1}>*/}
                                {/*        <Text>Research Paper</Text>*/}
                                {/*        <Text fontSize={'sm'} color={'gray'}>asdfasdf</Text>*/}
                                {/*    </VStack>*/}
                                {/*</Box>*/}
                                {/*<Box bg={'white'} height={'16rem'} width={'12.3rem'} borderWidth={'1px'} borderColor={'lightgray'}>*/}
                                {/*    <CustomPlusIcon color={"green"}/>*/}
                                {/*    <VStack mt={2} align={'flex-start'} spacing={1}>*/}
                                {/*        <Text>Cover Letter</Text>*/}
                                {/*        <Text fontSize={'sm'} color={'gray'}>asdfasdf</Text>*/}
                                {/*    </VStack>*/}
                                {/*</Box>*/}
                            </HStack>
                        </VStack>
                    </VStack>
                    {generations.length !== 0 ?
                        <DocumentGridGenerationOld /> : <DocumentGridGenerationEmptyOld/>}
                </VStack>
            </FullScreenTemplateGenerationHomePage>
            <Box height={"9rem"}/>
            <LargeWithNewsletter />
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <ScanModal1 isOpenScanModal1={isOpenScanModal1} onCloseScanModal1={onCloseScanModal1} onOpenScanModal1={onOpenScanModal1}/>
            <ScanModalIntro1 isOpenScanModalIntro1={isOpenScanModalIntro1} onCloseScanModalIntro1={onCloseScanModalIntro1}
                             onOpenScanModalIntro1={onOpenScanModalIntro1}/>
        </div>
    );
};

export default UnifiedHomePageGenerationOld;









