import {
    Box,
    Text,
    Image,
    HStack,
    Avatar,
    useToast,
    IconButton,
    Icon,
    Heading,
    ListItem,
    UnorderedList, OrderedList, Td, Th, Table, Thead, Tbody, Tr, Link, Tooltip, Button,
} from "@chakra-ui/react";
import {whichMessageHeader} from "../../utils/pdf-page-helpers";
import React from "react";
import {ThreeDotLoading} from "../Reuseable/ThreeDotLoading";
import {useAuth} from "../../redux/AuthProvider";
import FollowUpButton from "./FollowUpButton";
import lightTheme from "../../utils/styles";
import {FiCopy, FiCornerDownRight} from "react-icons/fi";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks';
import {markdownComponents} from "../../utils/constants";
import {useTranslation} from "react-i18next";


export const MessageBubbleMultidoc = React.memo(({ message, isLastMessage, userBgColor, claudeBgColor, claudeColor,
                                  lastMessageRef, sessionToken, socketID, onCitationClick }) => {
    const auth = useAuth();
    const toast = useToast();
    const [firstName, lastName] = [localStorage.getItem("firstName"), localStorage.getItem("lastName")];
    const {t} = useTranslation();
    let citationMap = new Map();


    const renderCitation = (citation, citationMap) => {
        const match = citation.match(/\[(.*?\.pdf),\s*pages?\s*([\d,-\s]+)\]/i);
        if (!match) return citation;

        const [_, filename, pages] = match;
        const citationKey = `${filename.trim()}_${pages}`;
        const citationNumber = citationMap.get(citationKey);

        return (
            <Tooltip
                hasArrow
                label={`${filename.trim()} (pages ${pages})`}
                bg="black"
                color="white"
                placement="top"
            >
                <Button
                    size="xs"
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => onCitationClick(filename.trim(), pages.trim())}
                    mx="1"
                    display="inline-block"
                >
                    [{citationNumber}]
                </Button>
            </Tooltip>
        );
    };

    const escapeMarkdown = (text) => {
        return text
            .replace(/_/g, '\\_');    // Escape underscores
    };

    const whichMessage = (message) => {
        if (message.user === 'User') {
            if (message.type.includes("selection")) {
                return <Text>{message.selection_text}</Text>
            } else if (message.type.includes("follow_up")) {
                if (message.selection_text) {
                    const formattedMessage = message.selection_text;
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={formattedMessage} />
                } else {
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={message.selection_text} />
                }
            }
            else {
                if (message.question) {
                    const formattedMessage = message.question;
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={formattedMessage} />
                } else {
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={message.question} />
                }
            }
        } else if (message.user === 'Gemini') {
            if (message.response) {
                let formattedMessage = message.response.replace(/\n{2,}/g, '\n&nbsp;\n')
                    // Replace single newline with a line break
                    .replace(/\n/g, '  \n');

                // const citationMap = new Map();
                let currentNumber = 1;

                const citationRegex = /\[(.*?\.pdf),\s*pages?\s*([\d,-\s]+)\]/gi;
                let match;
                while ((match = citationRegex.exec(formattedMessage)) !== null) {
                    const [_, filename, pages] = match;
                    const citationKey = `${filename.trim()}_${pages.trim()}`;
                    if (!citationMap.has(citationKey)) {
                        citationMap.set(citationKey, currentNumber++);
                    }
                }

                const citationComponents = {
                    ...markdownComponents,
                    p: ({children}) => {
                        const childrenArray = React.Children.toArray(children);

                        const processText = (text) => {
                            const parts = text.split(/(\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\])/g);
                            return parts.map((part) => {
                                if (part.match(/\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\]/)) {
                                    return renderCitation(part, citationMap);
                                }
                                return part;
                            });
                        };

                        const content = childrenArray.map((child) => {
                            if (typeof child === "string") {
                                return processText(child);
                            }
                            return child;
                        });

                        return <Text fontSize={15} color='black'>{content}</Text>
                    },
                    li: ({children}) => {
                        const childrenArray = React.Children.toArray(children);

                        const processText = (text) => {
                            const parts = text.split(/(\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\])/g);
                            return parts.map((part) => {
                                if (part.match(/\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\]/)) {
                                    return renderCitation(part, citationMap);
                                }
                                return part;
                            });
                        };

                        const content = childrenArray.map((child) => {
                            if (typeof child === "string") {
                                return processText(child);
                            }
                            return child;
                        });

                        return <ListItem fontSize={15} color='black'>{content}</ListItem>
                    },
                    ul: ({children}) => {
                        const childrenArray = React.Children.toArray(children);

                        const processText = (text) => {
                            const parts = text.split(/(\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\])/g);
                            return parts.map((part) => {
                                if (part.match(/\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\]/)) {
                                    return renderCitation(part, citationMap);
                                }
                                return part;
                            });
                        };

                        const content = childrenArray.map((child) => {
                            if (typeof child === "string") {
                                return processText(child);
                            }
                            return child;
                        });

                        return <UnorderedList my={'0.4rem'} fontSize={15}>{content}</UnorderedList>
                    },
                    ol: ({children}) => {
                        const childrenArray = React.Children.toArray(children);

                        const processText = (text) => {
                            const parts = text.split(/(\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\])/g);
                            return parts.map((part) => {
                                if (part.match(/\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\]/)) {
                                    return renderCitation(part, citationMap);
                                }
                                return part;
                            });
                        };

                        const content = childrenArray.map((child) => {
                            if (typeof child === "string") {
                                return processText(child);
                            }
                            return child;
                        });

                        return <OrderedList my={'0.4rem'} fontSize={15}>{content}</OrderedList>
                    },
                    blockquote: ({children}) => {
                        const childrenArray = React.Children.toArray(children);

                        const processText = (text) => {
                            const parts = text.split(/(\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\])/g);
                            return parts.map((part) => {
                                if (part.match(/\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\]/)) {
                                    return renderCitation(part, citationMap);
                                }
                                return part;
                            });
                        };

                        const content = childrenArray.map((child) => {
                            if (typeof child === "string") {
                                return processText(child);
                            }
                            return child;
                        });

                        return <Box as="blockquote" borderLeft="4px solid" fontSize={15}
                        borderColor="gray.200" pl={"0.9rem"} my={"0.5rem"} fontStyle="italic">{content}</Box>
                    },
                    em: ({children}) => {
                        const childrenArray = React.Children.toArray(children);

                        const processText = (text) => {
                            const parts = text.split(/(\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\])/g);
                            return parts.map((part) => {
                                if (part.match(/\[.*?\.pdf,\s*pages?\s*[\d,\-–\s]+\]/)) {
                                    return renderCitation(part, citationMap);
                                }
                                return part;
                            });
                        };

                        const content = childrenArray.map((child) => {
                            if (typeof child === "string") {
                                return processText(child);
                            }
                            return child;
                        });

                        return <Text as="em" fontStyle="italic" fontSize={15}>
                            {content}
                        </Text>
                    },
                };

                formattedMessage = escapeMarkdown(formattedMessage);
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={citationComponents}
                    children={formattedMessage}
                />;
            } else {
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                    children={message.response} />
            }
        }
    }

    // const copyToClipboard = (text) => {
    //     navigator.clipboard.writeText(text)
    //         .then(() => {
    //             toast({
    //                 title: t("Copied!"),
    //                 status: "success",
    //                 duration: 3000,
    //                 isClosable: true,
    //             })
    //         })
    //         .catch(err => {
    //             console.error('Could not copy text: ', err);
    //         });
    // }
    const copyToClipboard = (text) => {
        // use the existing citationMap to replace citations with numbers
        text = text.replace(/\[(.*?\.pdf),\s*pages?\s*([\d,-\s]+)\]/gi, (match, filename, pages) => {
            const citationKey = `${filename.trim()}_${pages.trim()}`;
            const citationNumber = citationMap.get(citationKey);
            return `[${citationNumber}]`;
        });

        // Build the "Citations" section
        const citations = Array.from(citationMap.entries())
            .map(([key, number]) => {
                const lastUnderscoreIndex = key.lastIndexOf('_');
                const beforeLastUnderscore = key.substring(0, lastUnderscoreIndex);
                const afterLastUnderscore = key.substring(lastUnderscoreIndex + 1);
                return `[${number}] ${beforeLastUnderscore}, page ${afterLastUnderscore}`;
            })
            .join("\n");

        // Combine the main text and citations
        const result = citations
            ? `${text}\n**Citations**\n${citations}`
            : text;

        // Copy to clipboard
        navigator.clipboard.writeText(result)
            .then(() => {
                toast({
                    title: "Copied!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    };



    if (message.user === "Gemini" && message.response === "") {
        return (
            <Box
                key={message.id}
                bg={message.user.toLowerCase() === 'user' ? userBgColor : claudeBgColor}
                color={message.user.toLowerCase() === 'gemini' ? claudeColor : 'white'}
                alignSelf={message.user.toLowerCase() === 'user' ? 'flex-end' : 'flex-start'}
                p='1.5rem'
                ref={isLastMessage ? lastMessageRef : null}
                width="100%"
                justifyContent={"flex-start"}
                alignItems={"center"}
                flexDirection={"row"}
                display={"flex"}
            >
                <Box
                    width="2rem"
                    height="2rem"
                    borderRadius="full"
                    overflow="hidden"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    mr="1rem"
                >
                    <Image
                        src='/always_white_background_h_logo.png'
                        alt="Company Logo"
                        width="100%"
                        height="100%"
                        transform="scale(1.1)"
                        objectFit="cover"
                    />
                </Box>
                <ThreeDotLoading/>
            </Box>
        )
    }

    else {
        return (
            <Box
                key={message.id}
                bg={message.user.toLowerCase() === 'user' ? "white" : claudeBgColor}
                color={message.user.toLowerCase() === 'gemini' ? claudeColor : 'black'}
                p='1.5rem'
                ref={isLastMessage ? lastMessageRef : null}
                width="100%"
                alignItems={"flex-start"}
                flexDirection={"row"}
                display={"flex"}
            >
                <Box
                    width="2rem"  // Adjust the width as needed
                    height="2rem"  // Adjust the height as needed
                    borderRadius="full"  // Makes the image circular
                    overflow="hidden"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    mr="1rem"
                >
                    {auth.isLoggedIn && message.user === "User" ?
                        <Avatar name={firstName + " " + lastName} width={"100%"} height={"100%"} size={"sm"}/> :
                        !auth.isLoggedIn && message.user === "User" ?
                            <Image
                                src={"https://static.vecteezy.com/system/resources/thumbnails/005/544/718/small/profile-icon-design-free-vector.jpg"}
                                alt="Company Logo"
                                width="100%"
                                height="100%"
                                objectFit="cover"
                            /> :
                            <Image
                                src='/always_white_background_h_logo.png'
                                alt="Company Logo"
                                width="100%"
                                height="100%"
                                transform="scale(1.1)"
                                objectFit="cover"
                            />}



                </Box>
                <Box display={'flex'} flexDirection={"column"} flex="1">
                    {message.user.toLowerCase() === 'user' && message.type !== "general_multidoc" && (
                        <Text fontWeight={"700"} color={'black'} marginBottom={'0.8rem'}>
                            {whichMessageHeader(message.type, message)}</Text>
                    )}
                    {message.type === "ask_image" && message.user === "User" ?
                        <Image src={message.selection_text} alt="Image asked about" width="100%" height="100%"
                               objectFit="cover"/>
                        :
                        whichMessage(message)
                    }


                    <Box display={'flex'} flexDirection={"row"} alignItems={'center'}>
                        {/* {(message.user === "Gemini" && !auth.isThinking && !isLastMessage) ? <FollowUpButton
                            sessionToken={sessionToken} socketID={socketID}
                            claudeMessage={message}/> : null} */}
                        {message.user === "Gemini" && !auth.isThinking ?
                            <Tooltip hasArrow label={t("Copy this response")} bg='black' color="white" placement={'right'}>
                                <span>
                                    <Icon
                                        as={FiCopy}
                                        boxSize="1.4rem" // or any other size you desire
                                        cursor="pointer"
                                        mt={'1rem'}
                                        ml={'0.1rem'}
                                        onClick={() => copyToClipboard(message.response)}
                                    />
                                </span>
                            </Tooltip>
                            : null}
                    </Box>

                </Box>
            </Box>
        )
    }
}, (prevProps, nextProps) => {
    // Only re-render if these props change
    return prevProps.message.id === nextProps.message.id &&
           prevProps.isLastMessage === nextProps.isLastMessage &&
           prevProps.message.response === nextProps.message.response;
});






