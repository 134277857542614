import {useNavigate} from "react-router-dom";
import {Box, Button, HStack, Icon} from "@chakra-ui/react";
import lightTheme from "../../utils/styles";
import React from "react";

export const SideBarFileUploadButtonOld = ({ icon, label }) => {
    const navigate = useNavigate();

    return (
        <Button
            variant="ghost"
            w="100%"
            justifyContent="start"
            _hover={{ bg: lightTheme.colors.lightGray }}
            _active={{ bg: lightTheme.colors.lightGray }}
            borderRadius={0}
            py={7}
            onClick={() => {
                // navigate(`/pdf/${document.id}`)

            }}
        >
            <div style={{ width: '100%', display: 'block' }}>
                <HStack direction="row" width="100%" justifyContent={'flex-start'} spacing={4}>
                    <Icon as={icon} flexShrink={0} />
                    <Box
                        fontSize="sm"
                        fontWeight="normal"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {label}
                    </Box>
                </HStack>
            </div>
        </Button>
    );
};
