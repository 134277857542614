import React, {Component} from "react";
import {
    PdfLoader,
    PdfHighlighter,
    Highlight,
    Popup,
    AreaHighlight,
} from "@brandonyan5/react-pdf-highlighter";
import {MySpinner} from "../../components/Reuseable/MySpinner";
import { Sidebar } from "../../components/SidebarComponents/Sidebar";
import CustomTip from "../../components/CustomTips/CustomTip";
import "../../style/App.css";
import {
    Box,
    Button,
    Flex,
    Grid,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    VStack
} from "@chakra-ui/react";
import CustomTipImage from "../../components/CustomTips/CustomTipImage";
import HighlightPopup from "./HighlightPopup";
import {getClaudeResponse, postChatPair} from "../../utils/api";
import {
    addHighlightHelper, fetchPDFDocumentProxy,
    parseIdFromHash,
    resetHash,
    updateHighlightHelper, uploadHighlight, deleteHighlightHelper
} from "../../utils/pdf-page-helpers";
import { connect } from 'react-redux';
import {
    addMessageInDocument, addToCurrentChatResponse,
    setCurrentChatClaudeID,
    setCurrentChatQuestion,
    setCurrentChatSelectionText,
    setCurrentChatType,
    setCurrentChatUserID,
    setHighlightsInDocument,
    toggleIsChatVisible,
    toggleIsFullScreen,
    appendToMessageInDocument, updateMessageInDocument, resetCurrentChatResponse, setWhichTab
} from "../../redux/reducers";
import { v4 as uuidv4 } from 'uuid';
import FullScreenTemplate from "../../templates/FullScreenTemplate";
import PDFPageHeader from "./PDFPageHeader";
import lightTheme from "../../utils/styles";
import {FaArrowsAltH} from "react-icons/fa";
import CustomTipGeneration from "../../components/CustomTips/CustomTipGeneration";
import {ImFilePdf} from "react-icons/im";
import {SideBarFileUploadButton} from "./SideBarFileUploadButton";
import {SidebarGeneration} from "../../components/SidebarComponents/SidebarGeneration";
import FullScreenTemplateGeneration from "../../templates/FullScreenTemplateGeneration";
import {SideBarFileUpload} from "./SideBarFileUpload";
import {SidebarGenerationOld} from "../../components/SidebarComponents/SidebarGenerationOld";
import {SideBarFileUploadOld} from "./SideBarFileUploadOld";

class PDFPageGenerationOld extends Component {
    state = {
        areaSelectionEnabled: false,
        currentPage: 1,
        totalPages: 14,
        thumbnails: [],
        isRendering: false,
        renderQueue: [],
        zoomLevel: 1,
        isResizing: false,
        panelWidth: 20,  // initial width percentage of the left panel
    };

    handleMouseDown = (e) => {
        this.setState({
            isResizing: true,
            initialPos: e.clientX,
        });
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseUp = () => {
        this.setState({
            isResizing: false,
        });
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseMove = (e) => {
        if (this.state.isResizing) {
            const containerRect = this.container.getBoundingClientRect();
            const newPanelWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;

            // you can also add some conditions to set min and max width limits
            this.setState({ panelWidth: newPanelWidth });
        }
    }

    render() {
        return (
            <FullScreenTemplateGeneration generationTitle={this.props.documentGeneration.name} generation={this.props.documentGeneration}>
                <div className="App" style={{ display: "flex", height: '100%',
                    width:'100%', }} ref={ref => this.container = ref}>
                    <div style={{
                        height: "100%",
                        width: this.props.isChatVisible ? `${this.state.panelWidth}%` : '100%',
                        position: "relative",
                        backgroundColor: "white",
                    }}>
                        <SideBarFileUploadOld generation={this.props.documentGeneration}/>
                    </div>
                    {/*DIVIDER*/}
                    <div
                        onMouseDown={this.handleMouseDown}
                        style={{
                            cursor: 'ew-resize',
                            width: '0.3rem',
                            backgroundColor: 'grey',
                            height: 'calc(100% + 3rem)',
                            position: 'relative', // Needed to position the icon
                        }}
                    >
                        <span
                            style={{
                                position: 'absolute', // Position the icon absolutely within the divider
                                top: '50%', // Center vertically
                                left: '50%', // Center horizontally
                                transform: 'translate(-50%, -50%)', // Adjust the exact centering
                                fontSize: '12px', // Adjust the size as needed
                                color: 'white', // Color of the icon, change as needed
                                userSelect: 'none', // Prevent text selection
                            }}
                        >
                            <FaArrowsAltH size={'1rem'} color={'black'}/>
                        </span>
                    </div>
                    {/*DIVIDER*/}
                    {/*SIDE BAR*/}
                    {this.props.isChatVisible ?
                        <SidebarGenerationOld
                            documentGeneration={this.props.documentGeneration}
                            sessionTokenGeneration={this.props.sessionTokenGeneration}
                            width={100 - this.state.panelWidth}
                            socketIDGeneration={this.props.socketIDGeneration}
                            socketGeneration={this.props.socketGeneration}
                        /> : null}
                    {/*SIDE BAR*/}
                </div>
            </FullScreenTemplateGeneration>
        )
    }
}

const mapStateToProps = state => ({
    isChatVisible: state.settings.isChatVisible,
    currentChatUserIDGeneration: state.settings.currentChatUserIDGeneration,
    currentChatClaudeIDGeneration: state.settings.currentChatClaudeIDGeneration,
    currentChatSelectionTextGeneration: state.settings.currentChatSelectionTextGeneration,
    currentChatQuestionGeneration: state.settings.currentChatQuestionGeneration,
    currentChatTypeGeneration: state.settings.currentChatTypeGeneration,
    currentChatResponseGeneration: state.settings.currentChatResponseGeneration,
});

const mapDispatchToProps = {
    addMessageInDocument,
    appendToMessageInDocument,
    updateMessageInDocument,
    setCurrentChatQuestion,
    setCurrentChatUserID,
    setCurrentChatClaudeID,
    setCurrentChatSelectionText,
    setCurrentChatType,
    addToCurrentChatResponse,
    resetCurrentChatResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFPageGenerationOld);
