import { useNavigate } from "react-router-dom";
import { Box, Button, HStack, Icon, useDisclosure, VStack, Text } from "@chakra-ui/react";
import lightTheme from "../../utils/styles";
import React, { useEffect } from "react";
import { SideBarFileUploadButton } from "./SideBarFileUploadButton";
import { ImFilePdf } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import GeneratePromptScreen2 from "../../components/Modals/Generation/GeneratePromptScreen2";
import { setIsUploadSourcesModalOpen } from "../../redux/reducers";
import { IoDocumentsOutline } from "react-icons/io5";
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import { useTranslation } from "react-i18next";
import DeleteScreenGeneration from "../../components/Modals/DeleteModal/DeleteScreenGeneration";
import DeleteScreenMultidocSource from "../../components/Modals/DeleteModal/DeleteScreenMultidocSource";
import { MdCloudUpload } from "react-icons/md";
import { FaArrowUp } from "react-icons/fa";


export const SideBarFileUpload = ({ generation, sessionTokenGeneration }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const documents = useSelector(state => state.documents.savedDocuments)
    const { isOpen: isOpenGeneratePromptScreen2, onOpen: onOpenGeneratePromptScreen2, onClose: onCloseGeneratePromptScreen2 } = useDisclosure();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const { t } = useTranslation();

    return (
        <VStack width={'100%'} height={'100%'} >
            {generation.files.length === 0 ?
                <VStack width={'100%'} height={'40%'}>
                    <VStack mt={7} maxW={'60%'} spacing={4}>
                        <Text fontSize={'lg'} textAlign={'center'} fontWeight={'bold'}>{t("No sources uploaded")}</Text>
                        <Text fontSize={14.5} color={'gray'} textAlign={'center'}>{t("Sources are cited in the response")}</Text>
                    </VStack>
                </VStack> :
                <VStack width={'100%'} height={'78%'}>
                    <Box 
                        width={'100%'}
                        flex={1}
                        overflowY={'auto'}
                        pr={2}
                    >
                        <VStack width={'100%'}>
                            {generation.files.map((file, index) => (
                                <SideBarFileUploadButton
                                    key={index}
                                    icon={ImFilePdf}
                                    label={file.name}
                                    file={file}
                                    generation={generation}
                                />
                            ))}
                        </VStack>
                    </Box>
                    <Icon as={FaArrowUp} />
                    <Text fontSize={'sm'} color={'gray'} textAlign={'center'}>{t("Click any source to view it")}</Text>
                </VStack>
            }

            <Box fontSize="5rem" onClick={() => {
                    onOpenGeneratePromptScreen2()
                    dispatch(setIsUploadSourcesModalOpen(true))
                }} _hover={{
                    cursor: 'pointer'
                }}>
                <MdCloudUpload color="pink"/>
            </Box>
            <Button
                loadingText="Submitting"
                size="lg"
                isLoading={false}
                onClick={() => {
                    onOpenGeneratePromptScreen2()
                    dispatch(setIsUploadSourcesModalOpen(true))
                }}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                px={6}
                _hover={{
                    bgGradient: 'linear(to-r, red.400,pink.400)',
                    boxShadow: 'xl',
                }}>
                {t("Upload Sources")}
            </Button>

            <GeneratePromptScreen2 isOpenGeneratePromptScreen2={isOpenGeneratePromptScreen2}
                onCloseGeneratePromptScreen2={onCloseGeneratePromptScreen2}
                generation={generation} sessionTokenGeneration={sessionTokenGeneration} />
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                onClosePricingScreen={onClosePricingScreen}
                onOpenPricingScreen={onOpenPricingScreen} />
        </VStack>
    );
};
