import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Box,
    VStack,
    HStack,
    Flex,
    Text,
    useColorModeValue,
    Textarea,
    Button,
    ListItem,
    useDisclosure,
    useToast,
    ListIcon,
    List,
    Icon,
} from '@chakra-ui/react';
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../redux/AuthProvider";
import lightTheme from "../../utils/styles";
import {FaCheckCircle} from "react-icons/fa";
import {AiOutlineArrowRight, AiOutlineSend} from "react-icons/ai";
import {
    setCurrentChatInputGeneration, setCurrentGenerationWordCount, setIsPricingModalOpen,
    setIsUploadSourcesModalOpen,
    setIsGeneratePDFVisible,
    setCurrentGeneratePDF,
    setCurrentGeneratePDFPage
} from "../../redux/reducers";
import SignUpScreen1 from "../Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../Modals/SignIn/SignInScreen1";
import GeneratePromptScreen2 from "../Modals/Generation/GeneratePromptScreen2";
import PricingScreen from "../Modals/Payments/PricingScreen";
import {useTranslation} from "react-i18next";
import {MessageBubbleMultidoc} from "./MessageBubbleMultidoc";
import { AiOutlineUpload } from "react-icons/ai";

export const Generation = ({ theDocumentGeneration, sessionTokenGeneration, socketIDGeneration,
                         stopClaudeResponseGeneration, startGeneration, pdfRef }) => {
    const {t} = useTranslation();
    const lastMessageRef = useRef(null);
    const userBgColor = useColorModeValue(lightTheme.colors.lightGray, lightTheme.colors.lightGray);
    const claudeBgColor = useColorModeValue(lightTheme.colors.lightGray, "#2e93ea");
    const claudeColor = useColorModeValue("#000000", "#E2E8F0");
    const currentChatInputGeneration = useSelector(state => state.settings.currentChatInputGeneration);

    const { isTypingGeneration, setIsTypingGeneration } = useAuth();
    const {isThinkingGeneration, setIsThinkingGeneration} = useAuth();
    const generations = useSelector(state => state.documents.savedGenerations);
    const currentMessagesGeneration = generations.find(doc => doc.id === theDocumentGeneration.id).messages;
    const [isInputFocused, setIsInputFocused] = useState(false);

    const isAskVisibleGeneration = useSelector(state => state.settings.isAskVisibleGeneration);
    const isFollowUpVisibleGeneration = useSelector(state => state.settings.isFollowUpVisibleGeneration);

    const dispatch = useDispatch();
    const textareaRef = useRef(null);
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const { isOpen: isOpenGeneratePromptScreen2, onOpen: onOpenGeneratePromptScreen2, onClose: onCloseGeneratePromptScreen2 } = useDisclosure();
    const toast = useToast();
    const [shouldReferenceSources, setShouldReferenceSources] = React.useState('true');
    const [sliderValue, setSliderValue] = React.useState(1000);
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();

    // startGeneration(prompt, sliderValue, shouldReferenceSources);
    const modifiedHandleNewMessage = () => {
        if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isChatExceeded") === "true") {
            toast({
                title: t("(Free Plan) Chat Limit Exceeded!"),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            dispatch(setIsPricingModalOpen(true));
            dispatch(setCurrentChatInputGeneration(''));
            if (textareaRef && textareaRef.current) {
                textareaRef.current.style.height = '3.5rem';
            }
            return;
        }

        if (currentMessagesGeneration && currentMessagesGeneration.length >= 3 && localStorage.getItem("userID") === null) {
            toast({
                title: t("You've reached your generation limit in guest mode!"),
                description: t("Please sign up to continue creating writing."),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            onOpenSignUpScreen1();
            dispatch(setCurrentChatInputGeneration(''));
            if (textareaRef && textareaRef.current) {
                textareaRef.current.style.height = '3.5rem';
            }
            return;
        }

        if (generations.find(doc => doc.id === theDocumentGeneration.id).files.length === 0) {
            toast({
                title: t("Please upload 1 or more sources!"),
                status: "error",
                duration: 7000,
                isClosable: true,
                position: "top",
            });
            dispatch(setCurrentChatInputGeneration(''));
            if (textareaRef && textareaRef.current) {
                textareaRef.current.style.height = '3.5rem';
            }
            return;
        }

        const chatInput = currentChatInputGeneration;
        dispatch(setCurrentChatInputGeneration(''));

        const shouldReferenceSourcesBoolean = shouldReferenceSources === 'true';

        startGeneration(chatInput, 999, shouldReferenceSourcesBoolean);
        dispatch(setCurrentGenerationWordCount(sliderValue));

        dispatch(setCurrentChatInputGeneration(''));
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = '3.5rem';
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (currentChatInputGeneration !== "" && !isTypingGeneration && !isThinkingGeneration) {
                modifiedHandleNewMessage();
            }
        }
    };

    useEffect(() => {
        const scrollMessageIntoView = () => {
            if (lastMessageRef.current) {
                // console.log('Height of last message:', lastMessageRef.current.offsetHeight);
                if (lastMessageRef.current.offsetHeight < 500) {
                    lastMessageRef.current.scrollIntoView({ behavior: 'instant', block: 'end' });
                }
            }
        };
        scrollMessageIntoView();

    }, [currentMessagesGeneration]);

    useEffect(() => {
        const scrollMessageIntoView = () => {
            if (lastMessageRef.current) {
                lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        };
        scrollMessageIntoView();

    }, []);

    const chatContainerRef = useRef(null);

    const placeholders = [t("Enter your prompt here..."),
        t("Shift + Enter to add a new line")];
    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true); // State to manage fade in and out

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
                setCurrentPlaceholder(placeholders[index]);
                setFade(true);
            }, 750); // Half the interval time to allow for fade in and fade out
        }, 1500);

        return () => clearInterval(intervalId);
    }, [index]);

    //isGeneratePDFVisible: false,
    //currentGeneratePDF: "",
    //currentGeneratePDFPage: 1,
    const isGeneratePDFVisible = useSelector(state => state.settings.isGeneratePDFVisible);
    const onCitationClick = (filename, page) => {
        console.log("Filename", filename)
        console.log("Page", page)

        // based on the filename, find the corresponding file in the generation
        const file = generations.find(doc => doc.id === theDocumentGeneration.id).files.find(file => file.name === filename);
        if (file) {
            //console.log("FILE FOUND", file);
            console.log("isGeneratePDFVisible", isGeneratePDFVisible)
            if (isGeneratePDFVisible) {
                // console.log("Here")
                dispatch(setIsGeneratePDFVisible(false));
                dispatch(setCurrentGeneratePDFPage(page));
                dispatch(setCurrentGeneratePDF(file.url))
                dispatch(setIsGeneratePDFVisible(true))
                // console.log("pdf ref here 1")
                // console.log("pdfRef", pdfRef)
                if (pdfRef && pdfRef.jumpToPage) {
                    // console.log("pdf ref here 2")
                    setTimeout(() => {
                        pdfRef.jumpToPage(page);
                    }, 100);
                }
            } else {
                // console.log("Here 22")
                dispatch(setCurrentGeneratePDFPage(page));
                dispatch(setCurrentGeneratePDF(file.url))
                dispatch(setIsGeneratePDFVisible(true))
                // if (pdfRef && pdfRef.jumpToPage) {
                //     setTimeout(() => {
                //         pdfRef.jumpToPage(page);
                //     }, 100);
                // }
            }
        }
    };

    const memoizedMessages = useMemo(() => {
        return currentMessagesGeneration.slice(-40).map((message, index, slicedArray) => {
            // console.log("MESSAGE INNER PEACE", message);
            const isLastMessage = index === slicedArray.length - 1;
            return {
                ...message,
                isLastMessage
            };
        });
    }, [currentMessagesGeneration]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" p={0} boxShadow="sm" borderRadius="md"
             border="0px" borderColor="gray.300" h="92.5vh" maxW="100%">
            <Box h={currentMessagesGeneration.length > 0 ? '90.5%': '100%'} overflowY="auto" className={currentMessagesGeneration.length > 7 ? "delayed-survey" : "normal"}
                 ref={chatContainerRef}>
                <Flex justifyContent={'flex-start'} flexDirection={'column'} height={'100%'}>
                    {currentMessagesGeneration.length > 0 ?
                        memoizedMessages.map(({ isLastMessage, ...message }) => (
                            <MessageBubbleMultidoc
                                key={message.id}
                                sessionToken={sessionTokenGeneration}
                                socketID={socketIDGeneration}
                                message={message}
                                isLastMessage={isLastMessage}
                                userBgColor={userBgColor}
                                claudeBgColor={claudeBgColor}
                                claudeColor={claudeColor}
                                lastMessageRef={lastMessageRef}
                                onCitationClick={onCitationClick}
                            />
                        )) :

                        <Flex justifyContent={'flex-start'} flexDirection={'column'} height={"100vh"} alignItems={"center"}>
                            <VStack spacing={12} mt={5} borderWidth={0} width={'70%'} height={'100%'} align={'center'}>
                                <VStack align={'center'} width={'100%'} spacing={3}>
                                    <Text fontSize={'2xl'} fontWeight={'800'} >{t("Chat with Multiple PDFs")}</Text>
                                    <Text fontSize={16} fontWeight={'600'} color={'gray'}>{t("Upload your sources.")}</Text>
                                    <Text fontSize={16} fontWeight={'600'} color={'gray'}>{t("Ask a question, get a response citing your sources.")}</Text>
                                </VStack>

                                <Flex justifyContent={'flex-start'} flexDirection={'column'} height={"100vh"} alignItems={"center"} bg={'white'}>
                                    <VStack spacing={5} align="center" bg={'white'} width={'100%'} mt={'-1.5rem'}>
                                        <VStack spacing={4}>
                                            <HStack spacing={6}>
                                                <Text fontSize="xl" fontWeight="bold">
                                                    {t("Examples")}
                                                </Text>
                                            </HStack>
                                            <VStack spacing={3} align={'center'}>
                                                {[t("Summarize each document in great detail"),
                                                    t("Where and in which document can I find info about ____"),
                                                    t("Create a comprehensive study guide based on the uploaded materials"),
                                                    t("Draft a discussion post that reacts to & analyzes the arguments of each document")].map((example, index) => (
                                                    <Button justifyContent="flex-start" variant="ghost" key={index}
                                                            width={['70%','75%', '90%', '95%', '111%']} py={'1.5rem'}
                                                            borderWidth={1} borderColor={lightTheme.colors.gray}
                                                            _hover={{ bg: lightTheme.colors.lightGray }} borderRadius={'10px'}
                                                            onClick={() => {
                                                                dispatch(setCurrentChatInputGeneration(example));
                                                                textareaRef.current.focus();
                                                            }}>
                                                        <Flex justifyContent="space-between" width="100%" alignItems="center">
                                                            <Text fontSize={"14px"} fontWeight={'600'} color={'blue'} mr={2}
                                                                  noOfLines={[2, 3, 4]} style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                                                                "{example}"
                                                            </Text>
                                                            <Icon as={AiOutlineArrowRight} color={'blue'} fontWeight={'bold'}/>
                                                        </Flex>
                                                    </Button>
                                                ))}
                                            </VStack>
                                        </VStack>
                                        <VStack spacing={4}>
                                            <HStack spacing={6}>
                                                <Text fontSize="xl" fontWeight="bold">{t("Tips")}</Text>
                                            </HStack>
                                            <VStack align="center" width={'83%'} spacing={3} borderWidth={1} borderColor={lightTheme.colors.gray}
                                                    borderRadius={'10px'} p={'1.5rem'}>
                                                <List spacing={5} textAlign="start">
                                                    <ListItem key={1}>
                                                        <Flex align="start">
                                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={4} />
                                                            <Text fontSize={"14px"} fontWeight={'500'}>{t("Provide clear, detailed instructions for best results.")}</Text>
                                                        </Flex>
                                                    </ListItem>
                                                    <ListItem key={2}>
                                                        <Flex align="start">
                                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={4} />
                                                            <Text fontSize={"14px"} fontWeight={'500'}>{t("Provide enough context in your question so the AI knows what you want.")}</Text>
                                                        </Flex>
                                                    </ListItem>
                                                    <ListItem key={2}>
                                                        <Flex align="start">
                                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={4} />
                                                            <Text fontSize={"14px"} fontWeight={'500'}>{t("Click \"View Sources\" above the PDF viewer to toggle between different sources")}</Text>
                                                        </Flex>
                                                    </ListItem>
                                                </List>
                                            </VStack>
                                        </VStack>
                                    </VStack>
                                </Flex>
                            </VStack>
                        </Flex>
                    }
                </Flex>
            </Box>
            {currentMessagesGeneration.length >= 0 ?
                <Box position="relative" mt="auto">
                    <form onSubmit={modifiedHandleNewMessage} style={{backgroundColor: 'white'}}>
                        <HStack spacing={0} width={'100%'} pt={1} bg='white' justifyContent={'flex-start'} alignItems={'flex-start'}>

                            {/* upload file icon here */}
                            <VStack>
                                <Button
                                    as="label"
                                    variant="outline"
                                    border="1px"
                                    borderColor="gray.300"
                                    color="black"
                                    leftIcon={<Box fontSize="1.25rem">
                                        <AiOutlineUpload />
                                    </Box>}
                                    size="sm"
                                    cursor="pointer"
                                    _hover={{ backgroundColor: 'gray.100' }}
                                    _active={{ backgroundColor: 'gray.200' }}
                                    marginLeft={'0.5rem'}
                                    onClick={() => {
                                        onOpenGeneratePromptScreen2()
                                        dispatch(setIsUploadSourcesModalOpen(true))
                                    }}
                                >
                                    Upload Sources
                                </Button>
                                <Text fontSize={'xs'} color={'gray'} fontWeight={'500'}>
                                    {generations.find(doc => doc.id === theDocumentGeneration.id).files.filter(file => file.isActive).length} sources active
                                </Text>
                            </VStack>

                            <VStack align="center" h="auto" marginLeft={'0.5rem'} marginBottom={'0.4rem'}
                                    borderWidth={'1px'} marginRight={'1.4rem'}
                                    borderColor={isInputFocused ? 'blue' : lightTheme.colors.darkGray}
                                    borderRadius={'md'} flexGrow={1}>
                                <HStack width={'100%'} position={'relative'} spacing={0} align={"flex-end"}
                                        justifyContent={'space-between'}>
                                    <Textarea
                                        name="message-input"
                                        ref={textareaRef}
                                        placeholder={generations.find(doc => doc.id === theDocumentGeneration.id).files.length !== 0 ?
                                            currentPlaceholder : "Upload a source to get started"}
                                        onKeyDown={handleKeyDown}
                                        value={currentChatInputGeneration}
                                        style={{
                                            border: 'none',
                                            borderRight: 'none',
                                            borderRightColor: "white",
                                            marginRight: 0,
                                            borderTopWidth: isAskVisibleGeneration || isFollowUpVisibleGeneration ? '0px' : '1px',
                                            borderTopLeftRadius: isAskVisibleGeneration || isFollowUpVisibleGeneration ? '0px' : '10px',
                                            borderTopRightRadius: isAskVisibleGeneration || isFollowUpVisibleGeneration ? '0px' : '10px'
                                        }}
                                        _focus={{boxShadow: 'none', outline: 'none'}}
                                        onChange={(e) => dispatch(setCurrentChatInputGeneration(e.target.value))}
                                        minH={'3.5rem'}
                                        onFocus={() => setIsInputFocused(true)}
                                        onBlur={() => setIsInputFocused(false)}
                                        overflowY="auto"  // Allow vertical scrollbar
                                        maxH="29rem"
                                        resize="none"  // Disable manual resizing
                                        onInput={(e) => {  // Auto-expand as text overflows
                                            e.currentTarget.style.height = 'auto';
                                            e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
                                        }}
                                        required
                                        width={'92%'}
                                    />
                                    <Box width={'2.85rem'}>
                                        <Button
                                            aria-label="Send message"
                                            type="submit"
                                            isDisabled={currentChatInputGeneration === ""}
                                            position="relative"
                                            cursor="pointer"
                                            _hover={{backgroundColor: 'white'}}
                                            borderRadius="md"
                                            backgroundColor={"white"}
                                            transition="background 0.2s"
                                            height={'2.5rem'}
                                            width={'100%'}
                                            size={'32'}
                                            onClick={() => {
                                                modifiedHandleNewMessage();
                                            }}
                                        >
                                            <AiOutlineSend style={{fontSize: '22px'}}/>
                                        </Button>
                                    </Box>
                                </HStack>
                            </VStack>
                        </HStack>
                    </form>
                </Box>
                : null}
            {currentMessagesGeneration.length !== 0 ?
                <Box justifyContent={"flex-end"} display={"flex"} mr={'1.4rem'} mb={2}>
                    <Text fontSize={"xs"} color={"gray"}>{t("Shift + Enter to add a new line")}</Text>
                </Box>
                : null}
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1}
                           onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1}
                           onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
            <GeneratePromptScreen2 isOpenGeneratePromptScreen2={isOpenGeneratePromptScreen2}
                                   onCloseGeneratePromptScreen2={onCloseGeneratePromptScreen2}
                                   generation={theDocumentGeneration} sessionTokenGeneration={sessionTokenGeneration}/>
        </Box>
    );
};
