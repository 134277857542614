import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux";
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react'
import {AuthProvider} from "./redux/AuthProvider";
import { PostHogProvider} from 'posthog-js/react'
// import { GoogleOAuthProvider } from '@react-oauth/google';
//
// const clientId = '849892643381-nk2pjd3tta8peg72hcgssp7p9cabfmvg.apps.googleusercontent.com';

const options = {
    api_host: "https://app.posthog.com",
    session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
            password: false,
            color: false,
            date: false,
            'datetime-local': false,
            email: false,
            month: false,
            number: false,
            range: false,
            search: false,
            tel: false,
            text: false,
            time: false,
            url: false,
            week: false,
            textarea: false,
            select: false,
        }
    }
}


const hostname = window.location.hostname;
if (hostname === "dev.honeybear.ai" || hostname === "localhost") {
    // console.log = function() {};
} else {
    console.log = function() {};
}
// const originalConsoleError = console.error;

// console.error = (...args) => {
//     if (typeof args[0] === 'string' && args[0].includes('Encountered two children with the same key')) {
//         // Ignore this specific warning
//         return;
//     }
//     originalConsoleError(...args); // Log other warnings normally
// };

ReactDOM.render(
    <Provider store={store}>
        <ChakraProvider>
            <PostHogProvider
                apiKey={"phc_mgfkPiCsN46j9nPI2LOVUZvpE7v3KaiWEKKvRKYY81k"}
                options={options}>
                <AuthProvider>
                    <App/>
                </AuthProvider>
            </PostHogProvider>
        </ChakraProvider>
    </Provider>
    , document.getElementById("root"));



